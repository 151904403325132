import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { JsonSchemaComponent } from './views/pages/devices/view-device/setup-device/json-schema.component';
import { BaseComponent } from './views/layout/base/base.component';
import { LoginComponent } from './views/pages/auth/login/login.component';
import { RegisterComponent } from './views/pages/auth/register/register.component';
import { DashboardComponent } from './views/pages/dashboard/dashboard.component';
import { BasicElementsComponent } from './views/pages/devices/add-device/basic-elements.component';
import { BasicTableComponent } from './views/pages/devices/view-devices/basic-table.component';
import { ViewDeviceComponent } from './views/pages/devices/view-device/view-device.component';
import { TestingComponent } from './views/pages/devices/testing/testing.component';
import { TestComponent } from './views/pages/test/test.component';
import { ViewTestComponent } from './views/pages/test/view-test/view-test.component';
import { NewBatchComponent } from './views/pages/test/new-batch/new-batch.component';
import { DeviceTestResultComponent } from './views/pages/test/device-test-result/device-test-result.component';
import { UpdateDeviceComponent } from './views/pages/devices/view-device/update-device/update-device.component';
import { InterSetupDeviceComponent } from './views/pages/devices/view-device/inter-setup-device/inter-setup-device.component';
import { PdfTestViewComponent } from './views/pages/test/view-test/pdf-test-view/pdf-test-view.component';
import { PrintSettingsComponent } from './views/pages/devices/view-device/print-settings/print-settings.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' }, 
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'add-device', component: BasicElementsComponent },
      { path: 'view-device', component: BasicTableComponent },
      { path: 'device/view-device/:deviceId/config', component:JsonSchemaComponent },
      { path: 'device/view-device/:deviceId', component: ViewDeviceComponent },
      { path: 'device/view-device/:deviceId/printSettings', component: PrintSettingsComponent },
      { path: 'device/view-device/testing', component:TestingComponent },
      { path: 'testing', component: TestComponent },
      { path: 'testing/allTest', component: ViewTestComponent },
      { path: 'testing/allTest/pdf', component: PdfTestViewComponent },
      { path: 'testing/newBatch', component: NewBatchComponent },
      { path: 'testing/allTest/viewResult', component: DeviceTestResultComponent },
      { path: 'device/view-device/:deviceId/update', component: UpdateDeviceComponent },
      { path: 'device/view-device/:deviceId/interoperabilidad', component: InterSetupDeviceComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
