<div class="row">
  <div class="col-5">
    <h2 class="mt-2 mb-3">Crear lote de pruebas</h2>
  </div>

  <div class="row">
    <div class="col-4">
      <h5 class="mt-2 mb-3">Seleccione las pruebas que desee realizar:</h5>
      <kendo-treeview style="--li-list-style: none;" [nodes]="data" [children]="children" [hasChildren]="hasChildren" textField="text" kendoTreeViewCheckable [(checkedKeys)]="checkedKeys" [checkBy]="key" [isChecked]="isChecked"></kendo-treeview>
    </div>

    <div class="col-md-6">
      <div class="row">
        <div class="col-4">
          <h6 class="mt-3">Selecciona un sistema:</h6>
        </div>
        <div class="col-7">
          <select id="opcionesDesplegable" class="form-select mt-3" [(ngModel)]="selectedDeviceId">
            <option *ngFor="let device of devices" [value]="device.id">{{ device.company }}</option>
          </select>
        </div>
      </div>     

      <div class="row">
        <div class="col-4">
          <h6>Verificación manual:</h6>
        </div>
        <div class="col-7">
          <input type="checkbox" id="manualVerification" name="manualVerification" class="ms-2">
        </div>
      </div>

      <div class="mt-3">
        <h6 class="mb-3">Añadir parámetros (Solo TC.3)</h6>
        <div class="row">
          <div class="col-md-3">
            <label for="startDate">StartDate</label>
            <input type="date" id="startDate" name="startDate" class="form-control" [(ngModel)]="tc3Params.startDate">
          </div>
          <div class="col-md-3">
            <label for="maxDuration">MaxDuration</label>
            <input type="number" id="maxDuration" name="maxDuration" class="form-control" [(ngModel)]="tc3Params.maxDuration">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12 d-flex justify-content-end">
      <button class="btn btn-success" (click)="getTests()" [disabled]="loading">
        {{ loading ? 'Creando lote (' + countdown + ')' : 'Crear lote' }}
      </button>
    </div>
  </div>
</div>
