<div class="row">
    <div class="col-4">
        <h2 class="mt-2 mb-3">Vista previa de las pruebas</h2>
    </div>

    <div class="col-1 offset-6">
        <button routerLink="./pdf" class="btn btn-success">
            Informe
        </button> 
    </div>


<div *ngIf="testLogs.length === 0">
    <p>Cargando datos...</p>
</div>

<div class="row">
<div *ngIf="testLogs.length > 0">
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col-3">Test</th>
                    <th scope="col-3">Estado</th>
                    <th scope="col-3">Resultado</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let log of testLogs">
                <tr *ngFor="let log of testLogs">
                    <td class="text-truncate">{{ log.test }}</td>
                    <td class="text-truncate">Finalizado</td>
                    <td><a (click)="viewResult(log)" class="btn btn-sm btn-primary">Ver Resultado</a></td>


                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #noData>
    <p>No hay datos disponibles</p>
</ng-template>