<nav class="navbar">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu"></i>
  </a>
  <div class="navbar-content">
    <form class="search-form">
      <div class="input-group">
       
       
      </div>
    </form>
    <ul class="navbar-nav">
      
      
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <img class="wd-30 ht-30 rounded-circle" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="profile">
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
          <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
            <div class="mb-3">
              <img class="wd-80 ht-80 rounded-circle" src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" alt="">
            </div>
            <div class="text-center">
              <p class="tx-16 fw-bolder">{{userInfo.name}}</p>
              <p class="tx-12 text-muted">{{userInfo.email}}</p>
            </div>
          </div>
          <ul class="list-unstyled p-1">
            <li class="dropdown-item py-2">
              <a routerLink="/view-device" class="d-flex text-body ms-0">
                <i class="feather icon-tool me-2 icon-md"></i>
                <span>Mis sistemas</span>
              </a>
            </li>
            <li class="dropdown-item py-2">
              <a (click)="onLogout($event)" class="d-flex text-body ms-0">
                <i class="feather icon-log-out me-2 icon-md"></i>
                <span>Cerrar sesión</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</nav>