import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'src/app/services/api/api.service';
import {FormControl, FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';

interface DeviceModel {
  añadir_sistema: {
    fabricante: string;
    nombre_sistema: string;
    version: string;
  };
}
@Component({
  selector: 'app-basic-elements',
  templateUrl: './basic-elements.component.html',
  styleUrls: ['./basic-elements.component.scss']
})
export class BasicElementsComponent implements OnInit {
  data: any;
  fabricante:any;
  deviceId: any
  routeAfterSubmit: string = ''
  token: string; 
  sub:string
  devices: any[];
  userId: number;
  selectedDeviceId: any;


  constructor(private readonly http: HttpClient,private api: ApiService,  private router: Router,private authService: AuthService,private cdr: ChangeDetectorRef) { }
 
  model: DeviceModel = {
    añadir_sistema: {
      fabricante: '',
      nombre_sistema: '',
      version: ''
    }
  };

  onDeviceChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedDeviceId = Number(selectElement.value);
    console.log("Device seleccionado desde el evento: ", this.selectedDeviceId);
    
    if (this.selectedDeviceId != null) {
      const selectedDevice = this.devices.find(device => device.id === this.selectedDeviceId);
      if (selectedDevice) {
        this.form.patchValue({
          añadir_sistema: {
            fabricante: selectedDevice.company,
            nombre_sistema: selectedDevice.systemName,
          }
        });
        
        this.cdr.detectChanges(); 
      } else {
        console.log('No se encontró el dispositivo con id:', this.selectedDeviceId);
      }
    } else {
      console.log('No se ha seleccionado ningún dispositivo.');
    }
  }
  

  ngOnInit(): void {
    this.authService.getKeycloakToken()
    .then(token => {    
      this.api.getUserSub(token).subscribe(
        claims => {
          console.log('User:', claims);
          this.sub= claims
          this.api.getUserId(claims, token)
            .subscribe(
              userId => {
                this.userId = userId;
                console.log('UserID:', this.userId);
                this.loadDevices(this.userId, token);
              },
              error => {
                console.error('Error al obtener el userID:', error);
              }
            );
        },
        error => {
          console.error('Error al obtener la información del usuario:', error);
        }
      );
    })
    .catch(error => {
      console.error('Error al obtener el token de Keycloak:', error);
    });
  }
  onSubmit() {
    const values = (this.form.value as any).añadir_sistema;
    
    if (this.selectedDeviceId) {
      const selectedDevice = this.devices.find(device => device.id === this.selectedDeviceId);
      if (selectedDevice) {
        const requestBody = {
          company: values.fabricante,
          systemName: values.nombre_sistema,
          version: values.version,
          entityForTesting: selectedDevice.entityForTesting,
          connectionInformation: selectedDevice.connectionInformation,
          setup: selectedDevice.setup,
          device: selectedDevice.json

        };
        
        this.api.registerCopyDevice(this.token,this.sub, requestBody).subscribe(
          deviceId => this.handleDeviceRegistrationResponse(deviceId),
          error => console.error('Error al registrar el sistema copiado:', error)
        );
      }
    } else {
      // Si no se ha seleccionado un dispositivo, construir la solicitud para register
      const requestBody = {
        company: values.fabricante,
        systemName: values.nombre_sistema,
        version: values.version,
        // Añadir aquí otros campos que puedan ser necesarios
      };
      
      this.api.registerDevice(this.token,this.sub, requestBody).subscribe(
        deviceId => this.handleDeviceRegistrationResponse(deviceId),
        error => console.error('Error al registrar el nuevo sistema:', error)
      );
    }
  }
  
  handleDeviceRegistrationResponse(deviceId: any) {
    if (deviceId) {
      this.deviceId = deviceId;
      this.navigateAfterSubmit();
    } else {
      console.error('No se recibió un deviceId del servidor');
    }
  }

  redirectTo(route: string) {
    this.routeAfterSubmit = route;
    this.onSubmit();
  }

  navigateAfterSubmit() {
    switch (this.routeAfterSubmit) {
      case 'viewDevices':
        this.router.navigate(['view-device']);
        break;
      case 'interoperabilidad':
        this.router.navigate([`device/view-device/${this.deviceId}/interoperabilidad`]);
        break;
      case 'funcionalidad':
        this.router.navigate([`device/view-device/${this.deviceId}/config`]);
        break;
      default:
        // Manejar cualquier otro caso o error
        break;
    }
  }


  loadDevices(userId : number,token: string) {
    this.api.showAllDevices(userId,token).subscribe(
      (data) => {
        this.devices = data;
        console.log(data);
      },
      (error) => {
        console.error('Error al obtener sistemas', error);
      }
    );
  }

  
  

  
  form = new FormGroup({
  });
  fields: FormlyFieldConfig[] = [

    {
      key: 'añadir_sistema',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'fabricante',
          type: 'input',
          templateOptions: {
            label: 'Empresa',
            required: true,
          },
          className: 'col',
        },
        {
          key: 'nombre_sistema',
          type: 'input',
          templateOptions: {
            label: 'Nombre del Sistema',
            required: true,
          },
          className: 'col',
        },
        {
          key: 'version',
          type: 'input',
          templateOptions: {
            label: 'Versión',
            required: true,
          },
          className: 'col',
        },
      ],
    },
  ]
}
