<div class="row">

    <div class="col-7 offset-1"><h3 class="card-title">Mis Sistemas</h3></div>
    <div class="col-2 offset-1 ">
      <button id="btnGroupDrop1" type="button"  class="btn btn-primary" routerLink="../add-device">
        Añadir nuevo
       </button>
    </div>
  </div>
<div class="row p-5">
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
       
  

        <div class="table-responsive pt-3">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Empresa</th>
                <th>Nombre del Sistema</th>
                <th>Versión</th>
                <th>Entidad para Pruebas</th>
                <th>Dirección del Sistema</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let device of devices">
                <td (click)="goToViewDevice(device.id)" style="color:rgb(8, 42, 155)"><a>{{ device.company }}</a></td>
                <td>{{ device.systemName }}</td> 
                <td>{{ device.version }}</td>
                <td>
                  <span *ngIf="device.entityForTesting; else notAssignedEntity">{{ device.entityForTesting }}</span>
                  <ng-template #notAssignedEntity>Sin asignar</ng-template>
                </td>
                <td>
                  <span *ngIf="device.connectionInformation; else notAssignedConnection">{{ device.connectionInformation }}</span>
                  <ng-template #notAssignedConnection>Sin asignar</ng-template>
                </td>
                
              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
    </div>
  </div>
</div>
