
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Informe de Resultados</title>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css">
    <style>
        body {
            font-size: 0.9rem;
        }
        .header-section,
        .footer-section,
        .title-section {
            background-color: #ffcc00; /* Ajusta este color según el diseño */
        }
        .title-section {
            padding: 0.375rem 0.75rem;
            border-bottom: 2px solid #000;
        }
        .content-section {
            padding: 0.75rem;
            border: 2px solid #000;
            margin-bottom: 1rem;
            word-wrap: break-word;

        }
        .bordered {
            border: 2px solid #000;
        }
        .signature-block {
            padding: 0.75rem;
            border-top: 2px solid #000;
            text-align: center;
            margin-top: 2rem;
        }
        .mb-1rem {
            margin-bottom: 1rem;
        }
    </style>
</head>
<div class="col-1 offset-10 mb-3  ">
    <button (click)="downloadPDF()" type="button" class="btn btn-primary">
        Descargar
    </button>
    
</div>
    <div id="contentToConvert" class="container my-4">
        <!-- Header -->
    
            <div class="header-section text-center py-2 bordered ">
                <h1 class="font-weight-bold mb-0 mt-3">INFORME DE ENSAYO</h1>
            </div>
     
       

        <!-- Datos del Cliente -->
        <div class="content-section">
            <div class="title-section">
                <strong>DATOS DEL CLIENTE</strong>
            </div>
            <div class="px-3 py-2">
                <p><strong>Nombre de la empresa:</strong> {{userInfo.company}}</p>
                <p><strong>Nombre:</strong> {{userInfo.country}}</p>
                <p><strong>Apellidos:</strong> {{userInfo.name}}</p>
                <p><strong>Dirección:</strong> {{userInfo.surnames}}</p>
                <p><strong>Teléfono:</strong> {{userInfo.phoneNumber}}</p>
                <p><strong>E-mail:</strong> {{userInfo.email}}</p>
            </div>
        </div>

        <!-- Identificación del Elemento a Ensayar -->
        <div class="content-section">
            <div class="title-section">
                <strong>IDENTIFICACIÓN DEL ELEMENTO A ENSAYAR</strong>
            </div>
            <div class="px-3 py-2">
                <p><strong>Nombre del sistema:</strong> {{deviceInfo.systemName}}</p>
                <p><strong>Empresa:</strong> {{deviceInfo.company}}</p>
                <p><strong>Url del servidor:</strong> {{deviceInfo.connectionInformation}}</p>
                <p><strong>Entidad a probar:</strong> {{deviceInfo.entityForTesting}}</p>

            </div>
        </div>

        <!-- Resultados -->
        <div class="content-section">
            <div class="title-section">
                <strong>RESULTADOS</strong>
            </div>
            <div class="px-3 py-2 col-10">
                <div  *ngFor="let testResult of testResults">
                    <p class="mt-2"><strong>Nombre del Test:</strong> {{testResult.test}}</p>
                    <p><strong>Resultado:</strong> {{testResult.result}}</p>
                </div>
            </div>
        </div>

        <!-- Conclusiones -->
        <div class="content-section">
            <div class="title-section">
                <strong>CONCLUSIONES</strong>
            </div>
            <div class="px-3 py-2">
                <p>[Conclusiones del ensayo]</p>
            </div>
        </div>

        <!-- Firma y Autorización -->
        <div class="content-section">
            <div class="d-flex">
            <div class="col-md-5 offset-1">
                <div class="signature-block">
                    <p class="mb-1rem">Fdo: [Nombre del Responsable]</p>
                </div>
            </div>
            <div class="col-md-5">
                <div class="signature-block">
                    <p class="mb-1rem">Fdo: [Nombre del Autorizador]</p>
                </div>
            </div>
        </div>
            <div class="footer text-center py-2">
                <p class="footer-text">Este informe solo afecta a la muestra sometida a ensayo y no puede reproducirse parcialmente sin la autorización de este laboratorio.</p>
                <div class="signature">
                    <p>Firmado por: [Nombre del responsable]</p>
                </div>
                <p class="footer-text">Laboratorio Central para Ensayo de Materiales y Equipos de Riego</p>
                <p class="footer-text">Camino de la Vega, s/n, 28830 San Fernando de Henares (Madrid)</p>
                <p class="footer-text">Correo electrónico: [email]</p>
                <p class="footer-text">Teléfono: [teléfono]</p>
            </div>
        </div>
    </div>
<!-- Footer -->


<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/umd/popper.min.js"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
    
    