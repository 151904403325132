<div class="row p-5">
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <!-- Inicio del formulario -->
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <!-- Inicio del formulario Formly -->
          <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
          <!-- Botones del formulario -->
          <div class="row">
            <div class="col-sm-3 offset-2">
              <button class="btn btn-success m-3 mx-auto" type="submit" (click)="redirectTo('sistemas')" [disabled]="!form.valid">Finalizar y ver mis Sistemas</button>
            </div>
            <div class="col-sm-3 offset-1">
              <button class="btn btn-primary m-3 mx-auto" type="submit"(click)="redirectTo('funcionalidad')" [disabled]="!form.valid">Seguir con la funcionalidad</button>
            </div>
          </div>
          <div *ngIf="form.touched && !form.valid" class="alert alert-danger m-3 mx-auto" role="alert">
            Por favor, rellena todos los campos obligatorios.
          </div>
        </form>
        <!-- Fin del formulario -->
      </div>
    </div>
  </div>
</div>
