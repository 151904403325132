import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
  private testBatchIdSource = new BehaviorSubject<number>(0); 
  public testBatchId$ = this.testBatchIdSource.asObservable();
  public inProgress: Subject<boolean> = new Subject<boolean>();

  private testResultsSource = new BehaviorSubject<any[]>([]);
  testResults$ = this.testResultsSource.asObservable();

  private testCountSource = new BehaviorSubject<number>(0);
  public testCount$ = this.testCountSource.asObservable();

  private newBatchSubject = new Subject<void>();
  newBatch$ = this.newBatchSubject.asObservable();

  private testResultSource = new BehaviorSubject<any>(null);
  public testResult$ = this.testResultSource.asObservable();

  private deviceIdSource = new BehaviorSubject<number | null>(null);
  public deviceId$ = this.deviceIdSource.asObservable();

  updateDeviceId(id: number): void {
    this.deviceIdSource.next(id);
  }

  notifyNewBatch(): void {
    this.newBatchSubject.next();
  }

  updateTestBatchId(id: number): void { 
    this.testBatchIdSource.next(id);
  }

  updateTestCount(count: number) {
    this.testCountSource.next(count);
  }

  updateTestResults(data: any[]) {
    this.testResultsSource.next(data);
  }

  updateTestResult(result: any): void {
    this.testResultSource.next(result);
  }
}
