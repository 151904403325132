import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-view-test',
  templateUrl: './view-test.component.html',
  styleUrls: ['./view-test.component.scss']
})
export class ViewTestComponent implements OnInit, OnDestroy {
  testLogs: any[] = [];
  batchId: number;
  countTest: number;
  private intervalId: any;
  token: string;

  constructor(
    private apiService: ApiService, 
    private sharedDataService: SharedDataService, 
    private router: Router, 
    private authService: AuthService
  ) {
    this.sharedDataService.testBatchId$.subscribe(data => {
      this.batchId = data;
    });
    this.sharedDataService.testCount$.subscribe(data => {
      this.countTest = data;
    });
  }

  ngOnInit(): void {
    this.authService.getKeycloakToken()
      .then(token => {
        this.token = token;
      })
      .catch(error => {
        console.error('Error al obtener el token de Keycloak:', error);
      });
    this.getAllTests();
    this.startRefreshing();
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  getAllTests() {
    this.apiService.getTestsByBatchId(this.batchId, this.token).subscribe(
      (data) => {
        this.testLogs = data;
        this.sharedDataService.updateTestResults(this.testLogs);
  
        if (this.testLogs.length >= this.countTest) {
          clearInterval(this.intervalId);
        }
      },
      (error) => {
        console.error('Error fetching test logs:', error);
      }
    );
  }
  

  startRefreshing() {
    this.intervalId = setInterval(() => {
      this.getAllTests();
    }, 5000);
  }

  status(num: any) {
    return num === 0 ? 'Finalizado' : 'En proceso';
  }

  viewResult(log: any) {
    this.sharedDataService.updateTestResult(log);
    this.router.navigate(['/testing/allTest/viewResult']);
  }
}
