import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss']
})
export class BasicTableComponent implements OnInit {

  devices: any[]; 
  deviceId: string | undefined; 
  token:string
  sub:any
  userId:number

  constructor(private apiService: ApiService,private route: ActivatedRoute,private router: Router,private authService: AuthService) { }

  loadDevices(userId : number,token: string) {
    this.apiService.showAllDevices(userId,token).subscribe(
      (data) => {
        this.devices = data;
        console.log(data);
      },
      (error) => {
        console.error('Error al obtener sistemas', error);
      }
    );
  }


  ngOnInit(): void {

    this.authService.getKeycloakToken()
    .then(token => {    
      this.apiService.getUserSub(token).subscribe(
        claims => {
          console.log('User:', claims);
          this.apiService.getUserId(claims, token)
            .subscribe(
              userId => {
                this.userId = userId;
                console.log('UserID:', this.userId);
                this.loadDevices(this.userId, token);
              },
              error => {
                console.error('Error al obtener el userID:', error);
              }
            );
        },
        error => {
          console.error('Error al obtener la información del usuario:', error);
        }
      );
    })
    .catch(error => {
      console.error('Error al obtener el token de Keycloak:', error);
    });
    
  }
  

  goToViewDevice(deviceId: number): void {
    this.router.navigate(['/device/view-device', deviceId]);
  }

  getUserClaims(token: string): void {
    this.apiService.getUserSub(token).subscribe(
      claims => {
        console.log('User :', claims);
      },
      error => {
        console.error('Error al obtener la información del usuario:', error);
      }
    );
  }

}
