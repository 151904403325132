<div class="container">
    <div class="row ">
      <div class="col-md-8 mx-auto  m-3">
        <form >
          <div class="form-group ">
            <aw-wizard #wizard>
              <aw-wizard-step [title]="'Paso 1'">
                <formly-form [form]="form" [fields]="fields" [model]="model" (ngSubmit)="onSubmit()" ></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-primary m-3 " type="button" (click)="goToNextStep(wizard)">Siguiente</button>

                  </div>
                </div>
              </aw-wizard-step>
              <aw-wizard-step [title]="'Paso 2'">
                <formly-form [form]="form1" [fields]="fields1" [model]="model1"></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-primary m-3 " type="button" (click)="goToNextStep(wizard)">Siguiente</button>

                  </div>
                </div>
              </aw-wizard-step>
              <aw-wizard-step [title]="'Paso 3'">
                <formly-form [form]="form2" [fields]="fields2" [model]="model2"></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-primary m-3 " type="button" (click)="goToNextStep(wizard)">Siguiente</button>

                  </div>
                </div>
              </aw-wizard-step>
              <aw-wizard-step [title]="'Paso 4'">
                <formly-form [form]="form3" [fields]="fields3" [model]="model3"></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-primary m-3 " type="button" (click)="goToNextStep(wizard)">Siguiente</button>

                  </div>
                </div>
              </aw-wizard-step>
              <aw-wizard-step [title]="'Paso 5'">
                <formly-form [form]="form4" [fields]="fields4" [model]="model4"></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-primary m-3 " type="button" (click)="goToNextStep(wizard)">Siguiente</button>

                  </div>
                </div>
              </aw-wizard-step>
              <aw-wizard-step [title]="'Paso 6'">
                <formly-form [form]="form5" [fields]="fields5" [model]="model5"></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-primary m-3 " type="button" (click)="goToNextStep(wizard)">Siguiente</button>

                  </div>
                </div>
              </aw-wizard-step>
              <aw-wizard-step [title]="'Paso 7'">
                <formly-form [form]="form6" [fields]="fields6" [model]="model6"></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-primary m-3 " type="button" (click)="goToNextStep(wizard)">Siguiente</button>

                  </div>
                </div>
              </aw-wizard-step>
              <aw-wizard-step [title]="'Paso 8'">
                <formly-form [form]="form7" [fields]="fields7" [model]="model7"></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-primary m-3 " type="button" (click)="goToNextStep(wizard)">Siguiente</button>

                  </div>
                </div>
              </aw-wizard-step>
              <aw-wizard-step [title]="'Paso 9'">
                <formly-form [form]="form8" [fields]="fields8" [model]="model8"></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-primary m-3 " type="button" (click)="goToNextStep(wizard)">Siguiente</button>

                  </div>
                </div>  
              </aw-wizard-step>
              <aw-wizard-step [title]="'Paso 10'">
                <formly-form [form]="form9" [fields]="fields9" [model]="model9"></formly-form>
                <div class="row">
                  <div class="col-2 offset-3">
                    <button class="btn btn-secondary m-3" type="button" (click)="goToPreviousStep(wizard)">Atrás</button>

                  </div>
                  <div class="col-2 offset-3">
                    <button class="btn btn-success m-3 mx-auto" type="button" (click)="finish()" routerLink="../">Finalizar</button>
                  </div>
                </div>  
              </aw-wizard-step>
            </aw-wizard>
          </div>
        </form>
      </div>
    </div>
  </div>
  