import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private keycloakService: KeycloakService,private router: Router) { }

  ngOnInit(): void {}

  onLoginWithKeycloak() {
    this.keycloakService.login().then(() => {
    }).catch(error => {
      console.error('Error al iniciar sesión con Keycloak:', error);
    });
  }
}
