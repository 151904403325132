<div class="col-md-12 mb-3 d-flex justify-content-end">
    <button (click)="downloadPDF()" type="button" class="btn btn-primary">
      Descargar
    </button>
  </div>
  
  <div id="contentToConvert" class="container my-4">
    <div class="card">
      <div class="card-body">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let pair of deviceDataPairs">
            <span class="font-weight-bold">{{ pair.key }}:</span>
            <span *ngIf="isArray(pair.value); else nonArrayValue">
              <ul class="list-group">
                <li class="list-group-item" *ngFor="let innerPair of pair.value">
                  <span class="font-weight-bold">{{ innerPair.key }}: </span>
                  <span class="text-muted"> {{ innerPair.value }}</span>
                </li>
              </ul>
            </span>
            <ng-template #nonArrayValue>
              <span class="text-muted">{{ pair.value }}</span>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  