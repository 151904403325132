import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

@Component({
  selector: 'app-device-test-result',
  templateUrl: './device-test-result.component.html',
  styleUrls: ['./device-test-result.component.scss']
})
export class DeviceTestResultComponent implements OnInit {
  @ViewChild('treeContainer', { static: false }) treeContainer: ElementRef;
  logResult: any;
  testId: number | null = null;

  constructor(private sharedDataService: SharedDataService,private router: Router) {}

  ngOnInit(): void {
    this.sharedDataService.testResult$.subscribe(result => {
      if (result && typeof result.result === 'string') {
        try {
          this.logResult = JSON.parse(result.result);
          if (!this.logResult || typeof this.logResult !== 'object' || Object.keys(this.logResult).length === 0) {
            this.logResult = null;
          }
          this.testId = result.test;
        } catch (e) {
          console.error('Error parsing logResult.result:', e);
          this.logResult = null;
        }
      } else {
        console.error('logResult.result is not a string or is empty', result);
        this.logResult = null;
      }
    });
  }

  isNotEmpty(object: any): boolean {
    return object && Object.keys(object).length > 0;
  }
  
  ngAfterViewInit() {
    if (this.logResult && this.treeContainer) {
      this.generarArbolJson(this.logResult, this.treeContainer.nativeElement);
    }
  }

  backToViewTest(): void {
    this.router.navigate(['/testing/allTest']); // Asegúrate de que esta ruta coincida con tus rutas definidas
  }

  private esJsonParseable(texto: any): boolean {
    try {
        JSON.parse(texto);
        return true;
    } catch (error) {
        return false;
    }
  }

  private generarArbolJson(data: any, container: HTMLElement): void {
    const details = document.createElement('details');
    const summary = document.createElement('summary');
    summary.textContent = Array.isArray(data) ? '[]' : '{}';
    details.appendChild(summary);
    
    const ul = document.createElement('ul');
    details.appendChild(ul);
    
    for (const [key, value] of Object.entries(data)) {
      const details = document.createElement('details');
      const summary = document.createElement('summary');
      summary.textContent = key; 
      details.appendChild(summary);
      
      const ul = document.createElement('ul');
      details.appendChild(ul);
      
      let processedValue = value;
      if (typeof value === 'string' && this.esJsonParseable(value)) {
          processedValue = JSON.parse(value);
      }
      
      if (typeof processedValue === 'object' && processedValue !== null) {
          this.generarArbolJson(processedValue, ul);
      } else {
          const li = document.createElement('li');
          li.textContent = String(processedValue);
          ul.appendChild(li);
      }
      
      container.appendChild(details);
  }
}

}
