<div class="row p-3">
  <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
    
      <div class="card-body">
        <h3 class="mb-5">Añadir un Sistema</h3>
        <form [formGroup]="form" >
          <div class="form-group ">
            <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
            
            <div class="row mt-3">
              <div class="col-sm-3">
                <h6>Seleciona un dispositivo  (Opcional)</h6>
              </div> 
              <div class="col-3 ">
                <div class="container">
                  <select class="form-select" (change)="onDeviceChange($event)" [(ngModel)]="selectedDeviceId">
                    <option *ngFor="let device of devices" [value]="device.id">{{ device.systemName }}</option>
                  </select>
                  </div>
                </div>
             
              <div class="col-sm-3 offset-1">
                <button class="btn btn-success mx-auto" type="submit" (click)="redirectTo('viewDevices')" [disabled]="!form.valid">Añadir Sistema</button>
              </div> 
            </div>
            <div class="col-12 mt-5" >
              <b>*Si desea copiar los datos de otro sistema para añadir otra versión del mismo puedes selecionar el dispositivo</b>
            </div>
            <div *ngIf="form.touched && !form.valid" class="alert alert-danger m-3 mx-auto" role="alert">
              Por favor, rellena todos los campos obligatorios.
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
