import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { Observable, of } from 'rxjs';
import { CheckedState } from '@progress/kendo-angular-treeview';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-new-batch',
  templateUrl: './new-batch.component.html',
  styleUrls: ['./new-batch.component.scss']
})
export class NewBatchComponent implements OnInit {

  loading: boolean = false;
  countdown: number = 3;
  token:string;
  constructor(
    private apiService: ApiService,
    private sharedDataService: SharedDataService,
    private router: Router,
    private authService: AuthService
  ) { }

  devices: any;
  selectedDeviceId: number;
  checkedKeys: string[] = [];
  key = "text";
  sub:any
  userId:any

  tc3Params: {
    startDate?: string;
    maxDuration?: string;
  } = {};

  public data: any[] = [
    {
      text: "Todas",
      items: [
        {
          text: "TC.0",
          items: [
            { text: "TC0_1" },
            { text: "TC0_2" },
         ],
        },
        {
          text: "TC.1",
          items: [
            { text: "TC1_1" },
            { text: "TC1_2" },
            { text: "TC1_3" },
            { text: "TC1_4" },
            { text: "TC1_5" },
            { text: "TC1_6" }],
        },
        {
          text: "TC.2",
          items: [
            { text: "TC2_1" },
            { text: "TC2_2" },
            { text: "TC2_3" },
            { text: "TC2_4" }],
        },
        {
          text: "TC.3",
          items: [
            { text: "TC3_1" },
            { text: "TC3_2" },
            { text: "TC3_3" },
            { text: "TC3_4" },
            { text: "TC3_5" },
            { text: "TC3_6" },
            { text: "TC3_7" },
            { text: "TC3_8" },
            { text: "TC3_9" },
            { text: "TC3_10" },
            { text: "TC3_11" },
            { text: "TC3_12" },
            { text: "TC3_13" },
            { text: "TC3_14" },
            { text: "TC3_15" },
            { text: "TC3_16" },
            { text: "TC3_17" },
            { text: "TC3_18" },
            { text: "TC3_19" },
            { text: "TC3_20" },
          ]
        },
        {
          text: "TC.4",
          items: [
            { text: "TC4_1" },
            { text: "TC4_2" },
            { text: "TC4_3" },
            { text: "TC4_4" },
          ],
        },
        {
          text: "TC.5",
          items: [
            { text: "TC5_1" },
            { text: "TC5_2" },
            { text: "TC5_3" },
            { text: "TC5_4" }],
        },
        {
          text: "TC.6",
          items: [
            { text: "TC6_1" },
            { text: "TC6_2" },
            { text: "TC6_3" },
            { text: "TC6_4" },
            { text: "TC6_5" },
            { text: "TC6_6" },
          ],
        },
        {
          text: "TC.7",
          items: [
            { text: "TC7_1" },
            { text: "TC7_2" },
            { text: "TC7_3" },
            { text: "TC7_4" },
            { text: "TC7_5" },
            { text: "TC7_6" },
            { text: "TC7_7" },
            { text: "TC7_8" },
            { text: "TC7_9" },
            { text: "TC7_10" },
            { text: "TC7_11" },
            { text: "TC7_12" },
            { text: "TC7_13" },
            { text: "TC7_14" },
          ]
        },
        {
          text: "TC.8",
          items: [
            { text: "TC8_1" },
            { text: "TC8_2" },],
        },
        {
          text: "TC.9",
          items: [
            { text: "TC9_1" },
            { text: "TC9_2" },],
        },
        {
          text: "TC.10",
          items: [
            { text: "TC10_1" },
            { text: "TC10_2" },
            { text: "TC10_3" },],
        },
        {
          text: "TC.11",
          items: [
            { text: "TC11_1" },
            { text: "TC11_2" },],
        },
      ],
    },
  ];

  
  ngOnInit(): void {
    this.authService.getKeycloakToken()
    .then(token => {    
      this.apiService.getUserSub(token).subscribe(
        claims => {
          console.log('User:', claims);
          this.apiService.getUserId(claims, token)
            .subscribe(
              userId => {
                this.userId = userId;
                console.log('UserID:', this.userId);
                this.loadDevices(this.userId, token);
              },
              error => {
                console.error('Error al obtener el userID:', error);
              }
            );
        },
        error => {
          console.error('Error al obtener la información del usuario:', error);
        }
      );
    })
    .catch(error => {
      console.error('Error al obtener el token de Keycloak:', error);
    });
  }

  
  getUserClaims(token: string): void {
    this.apiService.getUserSub(token).subscribe(
      claims => {
        console.log('User :', claims);
      },
      error => {
        console.error('Error al obtener la información del usuario:', error);
      }
    );
  }


  loadDevices(userId : number,token: string) {
    this.apiService.showAllDevices(userId,token).subscribe(
      (data) => {
        this.devices = data;
        console.log(data);
      },
      (error) => {
        console.error('Error al obtener sistemas', error);
      }
    );
  }


  onDeviceChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedDeviceId = Number(selectElement.value);
    this.getTests();
  }

  getTests(): void {
    this.sharedDataService.inProgress.next(true);
    const testsArray = this.getChecked();
  
    // Se eliminó la lógica de comprobación individual de los parámetros de tc3Params
    const tc3ParamsToSend = this.tc3Params;
  
    console.log('Tests to send:', testsArray);
    console.log('TC3 Parameters to send:', tc3ParamsToSend);
  
    this.apiService.getAllTestsForDevice(this.selectedDeviceId, testsArray, tc3ParamsToSend, this.token)
      .subscribe(
        response => {
          console.log('Selected Device ID:', this.selectedDeviceId);
          console.log('Response:', response);
          this.sharedDataService.inProgress.next(false);
          this.sharedDataService.notifyNewBatch();
          this.router.navigate(['../testing']);
        },
        error => {
          console.error("Error calling API:", error);
          this.sharedDataService.inProgress.next(false);
        }
      );
  }
  
  public children = (dataItem: any): Observable<any[]> => of(dataItem.items);
  public hasChildren = (dataItem: any): boolean => !!dataItem.items;

  public isChecked = (dataItem: any, index: string): CheckedState => {
    if (this.containsItem(dataItem)) {
      return "checked";
    }

    if (this.isIndeterminate(dataItem.items)) {
      return "indeterminate";
    }

    return "none";
  };

  private containsItem(item: any): boolean {
    return this.checkedKeys.indexOf(item[this.key]) > -1;
  }

  private isIndeterminate(items: any[] = []): boolean {
    let idx = 0;
    let item;

    while ((item = items[idx])) {
      if (this.isIndeterminate(item.items) || this.containsItem(item)) {
        return true;
      }

      idx += 1;
    }

    return false;
  }

  getChecked() {
    this.checkedKeys = this.checkedKeys.filter(key => key !== "Todas");
    for (let i = 0; i < this.checkedKeys.length; i++) {
      const prefix = this.checkedKeys[i].split('_')[0];
      switch (true) {
        case prefix === 'TC0':
          this.checkedKeys[i] = `tc0.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC10':
          this.checkedKeys[i] = `tc10.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC11':
          this.checkedKeys[i] = `tc11.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC1':
          this.checkedKeys[i] = `tc1.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC2':
          this.checkedKeys[i] = `tc2.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC3':
          this.checkedKeys[i] = `tc3.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC4':
          this.checkedKeys[i] = `tc4.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC5':
          this.checkedKeys[i] = `tc5.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC6':
          this.checkedKeys[i] = `tc6.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC7':
          this.checkedKeys[i] = `tc7.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC8':
          this.checkedKeys[i] = `tc8.${this.checkedKeys[i]}`;
          break;
        case prefix === 'TC9':
          this.checkedKeys[i] = `tc9.${this.checkedKeys[i]}`;
          break;
        default:
          console.error(`Unknown prefix: ${prefix}`);
      }
    }
    return this.checkedKeys;
  }
  
  
  
  

  


}

