<div class="row">
  <div class="col-2">
    <h2 class="mt-2 mb-3">{{device?.company}}</h2>
    <span class="badge rounded-pill p-2" *ngIf="showAlert" style="background-color: rgb(207, 140, 214);">En proceso de configuración</span>
  </div>
  <div class="col-1 offset-8">
    <div class="btn-group" role="group" ngbDropdown>
      <button id="btnGroupDrop1" type="button" ngbDropdownToggle class="btn btn-primary">
        Ajustes
      </button>
      <div ngbDropdownMenu aria-labelledby="btnGroupDrop1">
        <a ngbDropdownItem routerLink="./update">Editar</a>
        <a ngbDropdownItem routerLink="./interoperabilidad">Interoperabilidad</a>
        <a ngbDropdownItem routerLink="./config">Funcionalidad</a>
        <a ngbDropdownItem [routerLink]="['/device/view-device', device?.id, 'printSettings']">Ver detalles</a>
        <a ngbDropdownItem href="" (click)="false">Eliminar sistema</a>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-4 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-baseline mb-4">
          <h6 class="card-title mb-5 mt-3">Ultima bateria de pruebas</h6>
        </div>
        <apx-chart
          [series]="cloudStorageChartOptions.series"
          [chart]="cloudStorageChartOptions.chart"
          [colors]="cloudStorageChartOptions.colors"
          [plotOptions]="cloudStorageChartOptions.plotOptions"
          [fill]="cloudStorageChartOptions.fill"
          [stroke]="cloudStorageChartOptions.stroke"
          [labels]="cloudStorageChartOptions.labels"
        ></apx-chart>
        <div class="row mb-3 mt-4">
          <div class="col-6 d-flex justify-content-end">
            <div>
              <label class="d-flex align-items-center justify-content-end tx-10 text-uppercase fw-bold">Pruebas totales <span class="p-1 ms-1 rounded-circle bg-secondary"></span></label>
              <h5 class="fw-bolder mb-0 text-end">{{ tests?.length || 0 }}</h5>
            </div>
          </div>
          <div class="col-6">
            <div>
              <label class="d-flex align-items-center tx-10 text-uppercase fw-bold"><span class="p-1 me-1 rounded-circle bg-primary"></span>Pruebas pasadas</label>
              <h5 class="fw-bolder mb-0">{{ passedTestsCount }}</h5>
            </div>
          </div>
        </div>
        <div class="d-grid">
          <button class="btn btn-primary mt-4" routerLink="../../../testing">Realizar nueva bateria</button>
        </div>
      </div>
    </div>
  </div>

  <div class="col-8 grid-margin stretch-card">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <h6 class="card-title mt-3">Mis lotes de pruebas</h6>
          </div>
        </div>
        <div class="col-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive pt-3">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Descripción</th>
                      <th>Pruebas pasadas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let testBatch of tests; let i = index">
                      <td>{{ i + 1 }}</td>
                      <td>{{ testBatch.timeStamp }}</td>
                      <td>{{ testBatch.requestedTest }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
