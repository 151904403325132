import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-print-settings',
  templateUrl: './print-settings.component.html',
  styleUrls: ['./print-settings.component.scss']
})
export class PrintSettingsComponent implements OnInit {
  token: any;
  device: any;
  deviceDataPairs: { key: string, value: any }[] = [];

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.getKeycloakToken()
      .then(token => {
        this.token = token;
        this.route.params.subscribe(params => {
          const deviceId = params['deviceId'];
          if (deviceId) {
            this.loadDeviceData(deviceId);
          }
        });
      })
      .catch(error => {
        console.error('Error al obtener el token de Keycloak:', error);
      });
  }

  private loadDeviceData(deviceId: number): void {
    this.apiService.showDevice(deviceId, this.token).subscribe(
      (deviceData) => {
        this.device = deviceData;
        this.processDeviceData();
        console.log(this.device);
      },
      (error) => {
        console.error(error);
      }
    );
  }
  processDeviceData(): void {
    if (this.device) {
      // Create key-value pairs for top-level properties excluding the 'json' field
      const topLevelPairs = Object.entries(this.device)
        .filter(([key, value]) => key !== 'json' && value)
        .map(([key, value]) => ({ key, value }));
  
      // Process the 'json' field if it exists and is a stringified object
      let jsonPairs: { key: string; value: any; }[] = [];
      if (this.device.json && typeof this.device.json === 'string') {
        try {
          const nestedJson = JSON.parse(this.device.json);
          jsonPairs = this.flattenObject(nestedJson);
        } catch (error) {
          console.error('Error parsing JSON:', error);
        }
      }
  
      // Combine top-level pairs with the processed 'json' pairs
      this.deviceDataPairs = [...topLevelPairs, ...jsonPairs];
    }
  }
  
  flattenObject(obj: any, parentKey = ''): { key: string, value: any }[] {
    let keyValuePairs: { key: string, value: any }[] = [];
  
    for (const [key, value] of Object.entries(obj)) {
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        keyValuePairs = [...keyValuePairs, ...this.flattenObject(value, parentKey ? `${parentKey}.${key}` : key)];
      } else if (value || value === false) { // Include boolean false but exclude null or empty string
        keyValuePairs.push({ key: parentKey ? `${parentKey}.${key}` : key, value });
      }
    }
  
    return keyValuePairs;
  }
  isArray(value: any): boolean {
    return Array.isArray(value);
  }
  
  downloadPDF() {
    const content: HTMLElement | null = document.getElementById('contentToConvert');
    if (content) {
      html2canvas(content, {
        scrollY: -window.scrollY,
        scale: 1 // Asegúrate de establecer una escala adecuada para una mejor calidad.
      }).then(canvas => {
        const imgWidth = 208; // Ancho de la imagen para el tamaño A4.
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const pageHeight = 295;  // Altura de la página para el tamaño A4.
        const pageWidth = 210;
        const totalPages = Math.ceil(imgHeight / pageHeight);
  
        let pdf = new jsPDF('p', 'mm', 'a4');
        
        for (let i = 0; i < totalPages; i++) {
          if (i > 0) {
            pdf.addPage();
          }
          const srcImg = canvas.toDataURL('image/png');
          // Se calcula el margen superior para las imágenes que no están en la primera página.
          const margin = -(pageHeight * i) % imgHeight;
          pdf.addImage(srcImg, 'PNG', 0, margin, imgWidth, imgHeight);
        }
        pdf.save('informe-de-ensayo.pdf');
      }).catch(error => {
        console.error('Error al generar el PDF:', error);
      });
    } else {
      console.error('Elemento para convertir a PDF no encontrado');
    }

  }
  

isJsonObject(value: any): boolean {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
}

  
}
