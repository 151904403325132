import { Component, OnInit } from '@angular/core';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-pdf-test-view',
  templateUrl: './pdf-test-view.component.html',
  styleUrls: ['./pdf-test-view.component.scss']
})
export class PdfTestViewComponent implements OnInit {
  testResults: any[] = [];
  userId: number;
  deviceId: number | null;
  userInfo: any;
  deviceInfo: any;
  token: string;

  constructor(
    private sharedDataService: SharedDataService, 
    private router: Router, 
    private authService: AuthService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.sharedDataService.deviceId$.subscribe(deviceId => {
      this.deviceId = deviceId;
      console.log('Recuperado deviceId:', this.deviceId);
      if (this.deviceId != null) {
        this.loadDeviceDetails();
      }
    });
    
    this.loadUserDetails();
    this.loadTestResults();
  }

  loadDeviceDetails() {
    this.authService.getKeycloakToken().then(token => {
      this.apiService.getDeviceById(this.deviceId!, token).subscribe(
        deviceInfo => {
          this.deviceInfo = deviceInfo;
          console.log('Device Info:', this.deviceInfo);
        },
        error => {
          console.error('Error al obtener la información del dispositivo:', error);
        }
      );
    });
  }

  loadUserDetails() {
    this.authService.getKeycloakToken()
      .then(token => {
        this.token = token;
        this.apiService.getUserSub(token).subscribe(
          claims => {
            this.apiService.getUserId(claims, token)
              .subscribe(
                userId => {
                  this.userId = userId;
                  this.apiService.getUser(this.userId, token).subscribe(
                    userInfo => {
                      this.userInfo = userInfo;
                      console.log('User Info:', this.userInfo);
                    },
                    userError => {
                      console.error('Error al obtener la información del usuario:', userError);
                    }
                  );
                },
                error => {
                  console.error('Error al obtener el userID:', error);
                }
              );
          },
          error => {
            console.error('Error al obtener la información del usuario:', error);
          }
        );
      })
      .catch(error => {
        console.error('Error al obtener el token de Keycloak:', error);
      });
  }

  loadTestResults() {
    this.sharedDataService.testResults$.subscribe(data => {
      this.testResults = data;
      console.log('Test Results:', data);
    });
  }

  
  downloadPDF() {
    const content: HTMLElement | null = document.getElementById('contentToConvert');
    if (content) {
      html2canvas(content, {
        scrollY: -window.scrollY,
        scale: 1 // Asegúrate de establecer una escala adecuada para una mejor calidad.
      }).then(canvas => {
        const imgWidth = 208; // Ancho de la imagen para el tamaño A4.
        const imgHeight = canvas.height * imgWidth / canvas.width;
        const pageHeight = 295;  // Altura de la página para el tamaño A4.
        const pageWidth = 210;
        const totalPages = Math.ceil(imgHeight / pageHeight);
  
        let pdf = new jsPDF('p', 'mm', 'a4');
        
        for (let i = 0; i < totalPages; i++) {
          if (i > 0) {
            pdf.addPage();
          }
          const srcImg = canvas.toDataURL('image/png');
          // Se calcula el margen superior para las imágenes que no están en la primera página.
          const margin = -(pageHeight * i) % imgHeight;
          pdf.addImage(srcImg, 'PNG', 0, margin, imgWidth, imgHeight);
        }
        pdf.save('informe-de-ensayo.pdf');
      }).catch(error => {
        console.error('Error al generar el PDF:', error);
      });
    } else {
      console.error('Elemento para convertir a PDF no encontrado');
    }
  }
  
  
  
  
}
