<div class="row w-100 mx-0 auth-page">
  <div class="col-md-8 col-xl-6 mx-auto">
    <div class="card">
      <div class="row">
        <div class="col-md-4 pe-md-0">
          <div class="auth-side-wrapper" [ngStyle]="{ 'background-image': 'url(assets/images/others/regadio.jpg)' }"></div>
        </div>
        <div class="col-md-8 ps-md-0">
          <div class="auth-form-wrapper px-4 py-5">
            <a routerLink="." class="nobleui-logo d-block mb-2">Tragsa<span>CENTER</span></a>
            <h5 class="text-muted fw-normal mb-4">¡Bienvenido de nuevo!</h5>
            <button type="button" class="btn btn-primary me-2 mb-2" (click)="onLoginWithKeycloak()" routerLink="/dashboard">Iniciar sesión</button>
          </div>
              <a routerLink="/register" class="d-block mt-3 text-muted p-3">
                Pulsa aquí para crear una cuenta</a>
          </div>
        </div>
    </div>
  </div>
</div>
