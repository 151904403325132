<div class="row w-100 mx-0 auth-page mt-5">
  <div class="col-md-8 col-xl-6 mx-auto mt-5">
    <div class="card mt-5">
      <div class="row mt-5">
        <div class="col-md-4 pe-md-0">
      
          <div class="auth-side-wrapper" [ngStyle]="{ 'background-image': 'url(assets/images/others/planta.jpg)' }"></div>
        </div>
        <div class="col-md-8 ps-md-0 mt-5">
          <div class="auth-form-wrapper px-4 py-5 mt-5">
            <a routerLink="." class="nobleui-logo d-block mb-2 mt-5">Tragsa<span>CENTER</span></a>
            <h5 class="text-muted fw-normal mb-4">Crea una cuenta nueva</h5>
            <form class="forms-sample">
              <div class="mb-3">
                <label for="userName" class="form-label">Nombre de usuario </label>
                <input type="text" class="form-control" id="nombre" autocomplete="name" placeholder="Nombre de usuario">
            </div>
              <div class="mb-3">
                  <label for="nombre" class="form-label">Nombre</label>
                  <input type="text" class="form-control" id="nombre" autocomplete="name" placeholder="Nombre">
              </div>
              <div class="mb-3">
                  <label for="apellidos" class="form-label">Apellidos</label>
                  <input type="text" class="form-control" id="apellidos" autocomplete="family-name" placeholder="Apellidos">
              </div>
              <div class="mb-3">
                  <label for="email" class="form-label">Dirección de email</label>
                  <input type="email" class="form-control" id="email" placeholder="Email">
              </div>
              <div class="mb-3">
                <label for="password" class="form-label">Contraseña</label>
                <input type="password" class="form-control" id="password" autocomplete="current-password" placeholder="Contraseña">
            </div>
            
              <div class="mb-3">
                  <label for="telefono" class="form-label">Teléfono de contacto</label>
                  <input type="tel" class="form-control" id="telefono" autocomplete="tel" placeholder="Teléfono de contacto">
              </div>
              <div class="mb-3">
                  <label for="empresa" class="form-label">Empresa</label>
                  <input type="text" class="form-control" id="empresa" autocomplete="organization" placeholder="Empresa">
              </div>
              <div class="mb-3">
                  <label for="cargo" class="form-label">Cargo en la empresa</label>
                  <input type="text" class="form-control" id="cargo" autocomplete="organization-title" placeholder="Cargo en la empresa">
              </div>
              <div class="mb-3">
                  <label for="telefono_admin" class="form-label">Teléfono de contacto del administrador</label>
                  <input type="tel" class="form-control" id="telefono_admin" autocomplete="tel" placeholder="Teléfono de contacto del administrador">
              </div>
              <div class="mb-3">
                  <label for="pais" class="form-label">País</label>
                  <input type="text" class="form-control" id="pais" autocomplete="country" placeholder="País">
              </div>
              <div>
                  <button type="submit" class="btn btn-primary me-2 mb-2 mb-md-0" (click)="onRegister($event)">Registrarse</button>
              </div>
              <a routerLink="/dashboard" class="d-block mt-3 text-muted">¿Ya eres usuario? Iniciar sesión</a>
          </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
