import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription, catchError, of, tap, switchMap, forkJoin } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/authService/auth.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit, OnDestroy {
  testBatches: any[] = [];
  inProgress: boolean = false;
  private subscription: Subscription;
  testsByBatchId: any[] = [];
  private intervalId: any;
  token:string;

  currentTestCounts: { [batchId: number]: number } = {};
  deviceNames: { [deviceId: number]: string } = {};
  userId:number


  constructor(private apiService: ApiService, private sharedDataService: SharedDataService,private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.getKeycloakToken()
      .then(token => {    
        this.apiService.getUserSub(token).subscribe(
          claims => {
            this.apiService.getUserId(claims, token)
              .subscribe(
                userId => {
                  this.userId = userId;
                  console.log('id:', this.userId);
                },
                error => {
                  console.error('Error al obtener el userID:', error);
                }
              );
          },
          error => {
            console.error('Error al obtener la información del usuario:', error);
          }
        );
      })
      .catch(error => {
        console.error('Error al obtener el token de Keycloak:', error);
      });

    this.subscription = this.sharedDataService.inProgress.subscribe(
      (status: boolean) => {
        this.inProgress = status;
      }
    );

    this.sharedDataService.newBatch$.pipe(
      switchMap(() => this.getAllBatches()),
      tap(() => this.getAllTests())
    ).subscribe();

    this.startRefreshing();
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  refreshData() {
    this.getAllBatches().subscribe(() => {
      this.getAllTests();
      this.testBatches.forEach(batch => {
        this.getDeviceNameById(batch.deviceId);
      });
      console.log('All Device Names:', this.deviceNames);
    });
  }
  

  getAllBatches(): Observable<any[]> {
    return this.apiService.getAllBatches(this.token,this.userId).pipe(
      tap((data: any) => {
        this.testBatches = data;
        console.log(data)
      }),
      catchError((error: any) => {
        console.error('Error fetching test batches:', error);
        return of([]);
      })
    );
  }

  getDeviceNameById(deviceId: number): void {
    this.apiService.getDeviceById(deviceId, this.token).subscribe(
      (device: any) => {
        this.deviceNames[deviceId] = device.systemName;
        console.log('Device ID:', deviceId, 'Device Name:', this.deviceNames[deviceId]); 
      },
      (error: any) => {
        console.error('Error fetching device by ID:', error);
        this.deviceNames[deviceId] = 'Unknown'; 
      }
    );
  }
  

  getTestsByBatchId(testBatchId: number, requestedTest: number, deviceId: number): void {
    this.sharedDataService.updateTestCount(this.countTests(requestedTest));
    this.sharedDataService.updateTestBatchId(testBatchId);
    this.sharedDataService.updateDeviceId(deviceId);
    this.apiService.getTestsByBatchId(testBatchId,this.token).subscribe(
      (data: any) => {
        this.testsByBatchId = data;
        this.currentTestCounts[testBatchId] = this.testsByBatchId.length;
      },
      (error: any) => {
        console.error('Error fetching tests by batch ID:', error);
      }
    );
  }

  getBatchStatus(batch: any): string {
    return this.currentTestCounts[batch.id] === this.countTests(batch.requestedTest) ? 'Finalizado' : 'En curso';
  }


  getAllTests() {
    const requests = this.testBatches.map(batch => this.apiService.getTestsByBatchId(batch.id,this.token));
    forkJoin(requests).subscribe(responses => {
      responses.forEach((data, index) => {
        this.currentTestCounts[this.testBatches[index].id] = data.length;
      });
    });
  }

  getBadgeStyle(batch: any) {
    return this.getBatchStatus(batch) === 'Finalizado' ? 'bg-success' : 'bg-warning' ;
  }

  startRefreshing() {
    this.intervalId = setInterval(() => {
      this.refreshData();
    }, 5000);
  }

  countTests(input: any): number {
    const target = "tc";
    let count = 0;

    for (let i = 0; i <= input.length - target.length; i++) {
      if (input.substring(i, i + target.length) === target) {
        count++;
      }
    }

    return count;
  }


}
