import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  url: string = "https://tragsa-interoperabilidad.webdev.libnamic.eu";


  constructor(private http: HttpClient) { }

  private getHeaders(token: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
  }

  getTest(token: string): Observable<any> {
    const direction = `${this.url}/health`;
    return this.http.get(direction, { headers: this.getHeaders(token) });
  }

  getDeviceById(deviceId: number, token: string): Observable<any> {
    const endpoint = `${this.url}/devices/${deviceId}`; 
    return this.http.get(endpoint, { headers: this.getHeaders(token) });
  }

  getUser(userId: number, token: string): Observable<any> {
    const endpoint = `${this.url}/user/${userId}`;
    return this.http.get<any>(endpoint, { headers: this.getHeaders(token) });
  }

  getUserId(sub: string, token: string): Observable<number> {
    const direction = `${this.url}/user/sub/${sub}`;
    return this.http.get<number>(direction, { headers: this.getHeaders(token) });
  }

  getLastBatchNumber(token: string): Observable<number> {
    const endpoint = `${this.url}/testing/batchsNumber`;
    return this.http.get<number>(endpoint, { headers: this.getHeaders(token) });
  }


  getUserSub(token: string): Observable<string> {
    const endpoint = `${this.url}/user/sub`;
    return this.http.get<string>(endpoint, { headers: this.getHeaders(token), responseType: 'text' as 'json' });
  }

  updateDevice(token: string, deviceId: number, updateDeviceRequest: any): Observable<void> {
    const endpoint = `${this.url}/devices/${deviceId}`;
    return this.http.put<void>(endpoint, updateDeviceRequest, { headers: this.getHeaders(token) });
  }

  showAllDevices(userId: number, token: string): Observable<any[]> {
    const direction = `${this.url}/devices/show/${userId}`;
    return this.http.get<any[]>(direction, { headers: this.getHeaders(token) });
  }

  setupDevice(token: string, deviceId: number, deviceConfig: any): Observable<any> {
    const direction = `${this.url}/devices/${deviceId}/setup`;
    return this.http.put<any>(direction, deviceConfig, { headers: this.getHeaders(token) });
  }
  
  interSetupDevice(token: string, deviceId: number, deviceConfig: any): Observable<any> {
    const direction = `${this.url}/devices/${deviceId}/interSetup`;
    return this.http.put<any>(direction, deviceConfig, { headers: this.getHeaders(token) });
  }

  returnAllBatchById(token: string, deviceId: number): Observable<any[]> {
    const direction = `${this.url}/testing/batchs/${deviceId}`;
    return this.http.get<any[]>(direction, { headers: this.getHeaders(token) });
  }
  
  registerDevice(token: string, sub: string, requestBody: {
    company: string,
    systemName: string,
    version: string,
  }): Observable<number> {
    const direction = `${this.url}/devices/${sub}`;
      const body = requestBody;
      return this.http.post<number>(direction, body, { headers: this.getHeaders(token) });
  }
  registerCopyDevice(token: string, sub: string, requestBody: {
    company: string,
    systemName: string,
    version: string,
    connectionInformation?: string,
    entityForTesting?: string,
    device?: any,
    setup?: any,
    user?: any
  }): Observable<number> {
    const direction = `${this.url}/devices/copy/${sub}`;
    const body = requestBody;
    return this.http.post<number>(direction, body, { headers: this.getHeaders(token) });
  }
  
  getUserInfo(token: string): Observable<string> {
    const endpoint = `${this.url}/user/claims`;
    return this.http.get<string>(endpoint, { headers: this.getHeaders(token) });
  }
  
  registerUser(requestBody: {
    name: string;
    surnames: string;
    email: string;
    password:string;
    phoneNumber: string;
    company: string;
    companyPosition: string;
    adminPhoneNumber: string;
    country: string;
}): Observable<number> {
    const direction = `${this.url}/user/register`; 
    return this.http.post<number>(direction, requestBody, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }
  
  getAllTestsForDevice(deviceID: number, tests: string[], tc3Params: {
    startDate?: string;
    maxDuration?: string;
  } | null, token: string): Observable<any> {
    const direction = `${this.url}/testing/${deviceID}`;
    let params = new HttpParams();
    tests.forEach((test) => {
      params = params.append('classNames', test);
    });
    if (tc3Params) {
      params = params
        .append('startDate', tc3Params.startDate ?? '')
        .append('maxDuration', tc3Params.maxDuration ?? '');
    }
    
    const headers = this.getHeaders(token);
    return this.http.get(direction, { headers, params }); 
  }
  

  showDevice(deviceId: number, token: string): Observable<any> { 
    const direction = `${this.url}/devices/${deviceId}`;
    return this.http.get<any>(direction, { headers: this.getHeaders(token) }); 
  }



  getTestsByBatchId(testBatchId: number, token: string): Observable<any> { 
    const direction = `${this.url}/testing/batchs/${testBatchId}/tests`;
    return this.http.get(direction, { headers: this.getHeaders(token) });
  }

  getAllBatches(token: string, userId: number): Observable<any> {
    const direction = `${this.url}/testing/users/${userId}/batchs`;
    return this.http.get(direction, { headers: this.getHeaders(token) });
  }
  
  getTestUpdates(deviceID: number, classNames: string[], token: string): Observable<string> {
    const subject = new Subject<string>();
    const direction = `${this.url}/testing/execute/${deviceID}?classNames=${classNames.join(',')}&token=${encodeURIComponent(token)}`;
  
    if (!!window.EventSource) {
      const eventSource = new EventSource(direction);
  
      eventSource.onmessage = (event) => {
        subject.next(event.data);
      };
  
      eventSource.onerror = (error) => {
        eventSource.close();
        subject.error(error);
      };
  
    } else {
      setTimeout(() => {
        subject.error('EventSource not available in this browser');
      }, 0);
    }
  
    return subject.asObservable();
  }
  
}