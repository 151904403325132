<div class="row">
    <div class="col-4">
        <h2 class="mt-2 mb-3">Lotes de pruebas</h2>
    </div>
        <div class="col-1 offset-6">
            <div routerLink="newBatch" class="btn-group" role="group" ngbDropdown>
                <button class="btn btn-success">
                    Añadir
                </button>
          
            </div>
        </div>
</div>
<script>
    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });
</script>

<table class="table">
    <thead>
        <tr>
            <th scope="col">Fecha</th>
            <th scope="col">Sistema</th>
            <th scope="col">Estado</th>
            <th scope="col">Test Realizados</th>
            <th scope="col"></th>
        </tr>
    </thead>
<tbody>
    <tr *ngFor="let batch of testBatches">
        <td>{{ batch.timeStamp }}</td>
        <td>{{ deviceNames[batch.deviceId] || 'Loading...' }}</td>
        <td>
            <span [class]="getBadgeStyle(batch)" class="badge rounded-pill">{{ getBatchStatus(batch) }}</span>
        </td>

        <td data-toggle="tooltip" data-placement="top" title="{{batch.requestedTest}}">
            {{ currentTestCounts[batch.id] || 0 }} de {{ this.countTests(batch.requestedTest) }}
        </td>
        <td>
            <button (click)="getTestsByBatchId(batch.id, batch.requestedTest, batch.deviceId)" 
            routerLink="allTest" class="btn btn-primary">Ver pruebas</button>
    
        </td>
    </tr>
</tbody>
</table>