<div class="row p-5">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group ">
              <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
              <div class="text-center">
                <button class="btn btn-primary m-3 mx-auto" type="submit" (click)="finish()" routerLink="../" [disabled]="!form.valid">Guardar</button>
              </div>
              <div *ngIf="form.touched && !form.valid" class="alert alert-danger m-3 mx-auto" role="alert">
                Por favor, rellena todos los campos obligatorios.
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  