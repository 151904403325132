import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public keycloakUrl = 'https://tragsa-keycloak.libnamic.eu';
  public clientId = 'interoperabilidad-une-318002';
  public redirectUri = 'https://tragsa-interoperabilidad-front-dev.webdev.libnamic.eu/dashboard';
  public codeVerifier: string;
  public returnUrl: string = ''; 
  public baseUri = 'https://tragsa-interoperabilidad-front-dev.webdev.libnamic.eu';


  constructor(private http: HttpClient,private keycloakService: KeycloakService) { }

  async getKeycloakToken(): Promise<string> {
    try {
      if (await this.keycloakService.isLoggedIn()) {
        return await this.keycloakService.getToken();
      } else {
        throw new Error('User is not logged in with Keycloak.');
      }
    } catch (error) {
      console.error('Error al obtener el token de Keycloak:', error);
      throw error;
    }
  }
  initializeReturnUrl() {
    this.returnUrl = window.location.href;
    console.log('URL de retorno guardada:', this.returnUrl);
  }

  generateCodeChallengeAndVerifier(): Promise<{ verifier: string; challenge: string }> {
    const verifier = this.generateRandomString(128);
    return this.sha256(verifier).then(buffer => {
      const challenge = this.base64UrlEncode(buffer);
      this.codeVerifier = verifier; 
      return { verifier, challenge };
    });
  }

  exchangeCodeForToken(code: string): Observable<any> {
    const tokenEndpoint = `${this.keycloakUrl}/realms/tragsa/protocol/openid-connect/token`;
    const body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('client_id', this.clientId);
    body.set('code', code);
    body.set('redirect_uri', this.redirectUri);
    body.set('code_verifier', this.codeVerifier); 

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post(tokenEndpoint, body.toString(), { headers: headers });
  }

  private generateRandomString(length: number): string {
    const text = [];
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
      text.push(possible.charAt(Math.floor(Math.random() * possible.length)));
    }
    return text.join('');
  }

  async logout(): Promise<void> {
    try {
      const idToken = localStorage.getItem('id_token'); 
      if (!idToken) {
        throw new Error('ID token not found');
      }
      const logoutUrl = `${this.keycloakUrl}/realms/tragsa/protocol/openid-connect/logout?post_logout_redirect_uri=${encodeURIComponent(this.baseUri)}&id_token_hint=${encodeURIComponent(idToken)}`;
      window.location.href = logoutUrl;
    } catch (error) {
      console.error('Error durante el proceso de logout:', error);
      throw error;
    }
  }
  
  private async sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  private base64UrlEncode(buffer: ArrayBuffer): string {
    const uintArray = new Uint8Array(buffer);
    const encodedString = btoa(String.fromCharCode(...uintArray));
    return encodedString
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }
}
