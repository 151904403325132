import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss']
})
export class TestingComponent implements OnInit {

  deviceID: number = 13;
  testResults: any = {};
  showTable: boolean = false; 
  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  }

  // getTestResults() {
  //   this.apiService.getAllTestsForDevice(this.deviceID).subscribe(
  //     (data) => {
  //       this.testResults = data;
  //       this.showTable = true; 

  //     },
  //     (error) => {
  //     }
  //   );
  // }

}
