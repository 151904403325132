import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/authService/auth.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  userId: number;
  userInfo: any;

  constructor(
    @Inject(DOCUMENT) private document: Document, 
    private renderer: Renderer2,
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private keycloakService: KeycloakService

  ) { }

  ngOnInit(): void {
    this.authService.getKeycloakToken()
    .then(token => {    
      this.apiService.getUserSub(token).subscribe(
        claims => {
          console.log('User:', claims);
          this.apiService.getUserId(claims, token)
            .subscribe(
              userId => {
                this.userId = userId;
                this.apiService.getUser(this.userId, token).subscribe(
                  userInfo => {
                    this.userInfo = userInfo;
                    console.log('User Info nav bar:', this.userInfo);
                  },
                  userError => {
                    console.error('Error al obtener la información del usuario:', userError);
                  }
                );
                console.log('UserID:', this.userId);
              },
              error => {
                console.error('Error al obtener el userID:', error);
              }
            );
        },
        error => {
          console.error('Error al obtener la información del usuario:', error);
        }
      );
    })
    .catch(error => {
      console.error('Error al obtener el token de Keycloak:', error);
    });
  }

  

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  onLogout(e: Event) {
    e.preventDefault();
    this.authService.logout()
      .then(() => {
        console.log('Sesión cerrada correctamente');
        localStorage.removeItem('isLoggedin');
        this.router.navigate(['/auth/login']); 
      })
      .catch(error => {
        console.error('Error al cerrar la sesión:', error);
      });
  }
  

}
