import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/authService/auth.service';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-update-device',
  templateUrl: './update-device.component.html',
  styleUrls: ['./update-device.component.scss']
})
export class UpdateDeviceComponent implements OnInit {
  data: any;
  deviceId: any;
  token: any;


  // Define fields for Formly
  fields: FormlyFieldConfig[] = [
    {
      template: '<h4>Editar tu Sistema</h4><br>',
    },
    {
      key: 'añadir_sistema',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'fabricante',
          type: 'input',
          templateOptions: {
            label: 'Empresa',
            required: true,
          },
          className: 'col',
        },
        {
          key: 'nombre_sistema',
          type: 'input',
          templateOptions: {
            label: 'Nombre del sistema',
            required: true,
          },
          className: 'col',
        },
        {
          key: 'version',
          type: 'input',
          templateOptions: {
            label: 'Versión',
            required: true,
          },
          className: 'col',
        },
      ],
    },
  ];

  form = new FormGroup({}); 

  // Define model if needed, here it's just an empty object
  model: any = {};

  constructor(
    private api: ApiService, 
    private route: ActivatedRoute,
    private authService: AuthService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.deviceId = this.route.snapshot.paramMap.get('deviceId');
    if (this.deviceId && !isNaN(+this.deviceId)) {
      this.authService.getKeycloakToken().then(token => {
        this.token = token;
        this.api.showDevice(+this.deviceId, this.token).subscribe(
          (response) => {
            this.data = typeof response === 'string' ? JSON.parse(response) : response;
            console.log('Data received: ', this.data); // Añade esto para depurar
            console.log('Company: ', this.data.company); // Verificar acceso a la propiedad company

            this.setFormValues();
          },
          (error) => {
            console.error('Error:', error);
          }
        );
      });
    }
  }
  

  setFormValues() {
    if (this.data) {
      this.model = {
        'añadir_sistema': { 
          'fabricante': this.data.company,
          'nombre_sistema': this.data.systemName,
          'version': this.data.version,
        }
      };
      this.cdr.detectChanges();
    }
  }
  
  
  finish() {
    this.onSubmit()
  }

  onSubmit() {

    if (this.form.valid) {
      const values = this.form.value; 
      const jsonString = JSON.stringify(values); 
      const jsonObject = JSON.parse(jsonString);

      const requestBody = {
        company: jsonObject['añadir_sistema']['fabricante'],
        systemName: jsonObject['añadir_sistema']['nombre_sistema'],
        version: jsonObject['añadir_sistema']['version'],
  
      };
      this.api.updateDevice(this.token, this.deviceId, requestBody).subscribe(
        () => {
          console.log("Success");
          // Navigate away or indicate success
        },
        error => {
          console.error("Failure", error);
          // Handle error
        }
      );
    }
  }
}
