import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-view-device',
  templateUrl: './view-device.component.html',
  styleUrls: ['./view-device.component.scss']
})
export class ViewDeviceComponent implements OnInit {

  public cloudStorageChartOptions: any = {};
  obj = {
    primary        : "#6571ff",
    secondary      : "#7987a1",
    success        : "#05a34a",
    info           : "#66d1d1",
    warning        : "#fbbc06",
    danger         : "#ff3366",
    light          : "#e9ecef",
    dark           : "#060c17",
    muted          : "#7987a1",
    gridBorder     : "rgba(77, 138, 240, .15)",
    bodyColor      : "#000",
    cardBg         : "#fff",
    fontFamily     : "'Roboto', Helvetica, sans-serif"
  }
  device: any;
  tests: any[] = [];
  showAlert: boolean = true;
  token: string = '';

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    console.log('Initializing ViewDeviceComponent');
    this.authService.getKeycloakToken()
    .then(token => {
      console.log('Keycloak token received:', token);
      this.token = token;
      this.loadDeviceData();
    })
    .catch(error => {
      console.error('Error al obtener el token de Keycloak:', error);
    });

    this.cloudStorageChartOptions = this.getCloudStorageChartOptions(this.obj);
  }

  private loadDeviceData(): void {
    const deviceId = this.route.snapshot.params['deviceId'];
    console.log('Device ID from route:', deviceId);
    if (deviceId) {
      this.apiService.showDevice(deviceId, this.token).subscribe(
        (deviceData) => {
          console.log('Device data received:', deviceData);
          this.device = deviceData;
          if (this.device && this.device.setup != null) {
            this.showAlert = false;
          }
          this.loadBatchsByDeviceId(deviceId);
        },
        (error) => {
          console.error('Error loading device data:', error);
        }
      );
    }
  }

  private loadBatchsByDeviceId(deviceId: number): void {
    console.log('Loading batches for device ID:', deviceId);
    this.apiService.returnAllBatchById(this.token, deviceId).subscribe(
      (batchData) => {
        console.log('Batch data received:', batchData);
        this.tests = batchData;
        if (this.tests && this.tests.length > 0) {
          const latestBatch = this.tests[this.tests.length - 1];
          const passedTests = latestBatch.passed;
          const totalTests = latestBatch.total;
          const passedPercentage = (passedTests / totalTests) * 100;
  
          this.cloudStorageChartOptions.series = [passedPercentage];
        }
      },
      (error) => {
        console.error('Error loading batches:', error);
      }
    );
  }

  get passedTestsCount(): number {
    const count = this.tests?.filter(test => test.status === 1).length || 0;
    console.log('Passed tests count:', count);
    return count;
  }

  private getCloudStorageChartOptions(obj: any) {
    console.log('Setting cloud storage chart options with:', obj);
    return {
      series: [0],
      chart: {
        height: 260,
        type: "radialBar"
      },
      colors: [obj.primary],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 15,
            size: "70%"
          },
          track: {
            show: true,
            background: obj.light,
            strokeWidth: '100%',
            opacity: 1,
            margin: 5,
          },
          dataLabels: {
            showOn: "always",
            name: {
              offsetY: -11,
              show: true,
              color: obj.muted,
              fontSize: "13px"
            },
            value: {
              color: obj.bodyColor,
              fontSize: "30px",
              show: true
            }
          }
        }
      },
      fill: {
        opacity: 1
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Pruebas superadas"]
    };
  }
}
