<div class="container mt-3">
  <div class="row">
    <div class="col-6">
      <h2 class="mb-2">Resultados del Test: {{ testId }}</h2>
    </div>
    <div class="col-2 offset-4 ">
      <button class="btn btn-primary" (click)="backToViewTest()">Volver a Tests</button> 
    </div>
  </div>

  <ng-container *ngIf="logResult; else noDataTemplate">
    <div #treeContainer></div>
    <details style="margin-left:20px">
      <summary>JSON</summary>
      <!-- Usar el pipe json para la representación del objeto -->
      <pre>{{ logResult | json }}</pre>
    </details>
  </ng-container>
  <!-- ng-template para el contenido alternativo cuando no hay datos -->
  <ng-template #noDataTemplate>
    <p>No hay ninguna propiedad para realizar este test.</p>
  </ng-template>
</div>
