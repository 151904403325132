import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
  }

  onRegister(e: Event) {
    e.preventDefault();
    const requestBody = {
      name: (document.getElementById('nombre') as HTMLInputElement).value,
      userName: (document.getElementById('userName') as HTMLInputElement).value,
      surnames: (document.getElementById('apellidos') as HTMLInputElement).value,
      email: (document.getElementById('email') as HTMLInputElement).value,
      phoneNumber: (document.getElementById('telefono') as HTMLInputElement).value,
      company: (document.getElementById('empresa') as HTMLInputElement).value,
      companyPosition: (document.getElementById('cargo') as HTMLInputElement).value,
      adminPhoneNumber: (document.getElementById('telefono_admin') as HTMLInputElement).value,
      password: (document.getElementById('password') as HTMLInputElement).value,
      country: (document.getElementById('pais') as HTMLInputElement).value
    };
    this.apiService.registerUser(requestBody).subscribe((response) => {
      localStorage.setItem('isLoggedin', 'true');
      if (localStorage.getItem('isLoggedin')) {
        this.router.navigateByUrl('/login');      }
    });
  }

}
