<ngb-alert [dismissible]="false" [type]="'danger'">El formulario de sistema está pendiente</ngb-alert>

<div class="row">
    <div class="col-1">
        <h2 class="mt-2 mb-3">T-430</h2>

    </div>
    <div class="col-1 mt-3 ">
        <span class="badge rounded-pill p-2 " style="background-color: rgb(207, 140, 214);">En proceso de
            configuración</span>
    </div>

    <div class="col-1 offset-8">
        <div class="btn-group" role="group" ngbDropdown>
            <!-- <button (click)="getTestResults()" id="btnGroupDrop1" class="btn btn-success">
                Iniciar
            </button> -->
        </div>
    </div>
    <div class="col-1 ">
        <div class="btn-group" role="group" ngbDropdown>
            <button id="btnGroupDrop1" type="button" ngbDropdownToggle class="btn btn-primary">
                Ajustes
            </button>
            <div ngbDropdownMenu aria-labelledby="btnGroupDrop1">
                <a ngbDropdownItem routerLink="../config">Configurar sistema</a>
                <a ngbDropdownItem href="" (click)="false">Descargar informe</a>
                <a ngbDropdownItem href="" (click)="false">Eliminar sistema</a>
            </div>
        </div>
    </div>
</div>



<div class="col-12 grid-margin stretch-card">
    <table *ngIf="showTable">
        <thead>
            <tr>
                <th>Test</th>
                <th>Result</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let result of testResults | keyvalue">
                <td style="color: darkgreen; font: bolder;">{{ result.key }}</td>
                <td>
                    <pre style="white-space: normal;">{{ result.value | json }}</pre>
                </td>
            </tr>
        </tbody>
    </table>
</div>