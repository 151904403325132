import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { HttpClient } from '@angular/common/http';
import {FormControl, FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import {ActivatedRoute, Router  } from '@angular/router';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-inter-setup-device',
  templateUrl: './inter-setup-device.component.html',
  styleUrls: ['./inter-setup-device.component.scss']
})
export class InterSetupDeviceComponent implements OnInit {
  deviceId: number;
  routeAfterSubmit: string;
  deviceData: any;
  jsonData:any; 
  token:string

  constructor(
    private readonly http: HttpClient,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.route.paramMap.subscribe(params => {
      const routeDeviceId = params.get('deviceId');
      if (routeDeviceId) {
        this.deviceId = Number(routeDeviceId);
      }
    });
  }

  ngOnInit(): void {
    this.authService.getKeycloakToken()
    .then(token => {
      this.token = token; 
    })
    this.getDeviceDetails();
  }

  getDeviceDetails() {
    this.api.showDevice(this.deviceId,this.token).subscribe({
      next: (data) => {
        this.deviceData = data; 
        console.log(this.deviceData)
        if (this.deviceData && this.deviceData.json) {
          this.jsonData = JSON.parse(this.deviceData.json); 
          this.setFormValues(); 

        }
      },
      error: (error) => console.error('Error al obtener detalles del sistema:', error)
    });
  }

  setFormValues() {
    Object.keys(this.jsonData).forEach(key => {
      if (this.form.contains(key)) {
        this.form.get(key)?.patchValue(this.jsonData[key]);
      }
    });
  }
  

  finish() {
    this.onSubmit()
  }


  
  onSubmit() {
    if (this.deviceId == null) {
      console.error('DeviceId es nulo.');
      return; 
    }
  
    const values = this.form.value; 
    const jsonString = JSON.stringify(values); 
    const jsonObject = JSON.parse(jsonString);
    console.log(jsonObject)
    this.authService.getKeycloakToken()
    .then(token => {
      this.api.interSetupDevice(token,this.deviceId, jsonObject).subscribe(
        (response: any) => {
          this.navigateAfterSubmit(this.routeAfterSubmit);
        },
        error => {
          console.error('Error al enviar los datos del formulario:', error);
        }
      );
    })
    .catch(error => {
      console.error('Error al obtener el token de Keycloak:', error);
    });
 
  }
  
  redirectTo(route: string) {
    this.routeAfterSubmit = route;
  }
  
  navigateAfterSubmit(route: string) {
    switch (route) {
      case 'sistemas':
        this.router.navigate(['../../view-device']);
        break;
      case 'funcionalidad':
        this.router.navigate([`device/view-device/${this.deviceId}/config`]);
        break;
      default:
        break;
    }
  }
  
  form = new FormGroup({
  });
  model = {};
  fields: FormlyFieldConfig[] = [
    {
      template: '<h4>Añadir un Sistema</h4><br>',
    },
    {
      key: 'añadir_sistema_3',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'conexion',
          type: 'input',
          templateOptions: {
            label: 'Datos de conexión (servidor:puerto)',
            required: true,
          },
          className: 'col',
        },
        {
          key: 'pruebas',
          type: 'input',
          templateOptions: {
            label: 'Nombre de la entidad para pruebas',
            required: true,
          },
          className: 'col',
        },
      ],
    },
    {
      key: 'añadir_sistema_2',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'security',
          type: 'checkbox',
          templateOptions: {
            label: 'Activar seguridad con token'
          },
          className: 'col',
          defaultValue: false
        },
        {
          key: 'user',
          type: 'input',
          templateOptions: {
            label: 'Nombre de usuario',
            required: false,
          },
          className: 'col',
          expressionProperties: {
            'templateOptions.required': 'model.security',
          }
        },
        {
          key: 'password',
          type: 'input',
          templateOptions: {
            label: 'Contraseña',
            required: false,
          },
          className: 'col',
          expressionProperties: {
            'templateOptions.required': 'model.security',
          }
        },
        {
          key: 'autentificacion',
          type: 'input',
          templateOptions: {
            label: 'Dirección de autentificación',
            required: false,
          },
          className: 'col',
          expressionProperties: {
            'templateOptions.required': 'model.security', 
          }
        },
      ],
    },
    {
      key: 'irrigation',
      fieldGroupClassName: 'row',
      fieldGroup: [
          {
              key: 'comunication',
              type: 'input',
              templateOptions: {
                  type: 'number', 
                  label: 'Tiempo de comunicacion (segundos)',
              },
              className: 'col',
          },
          {
              key: 'startTime',
              type: 'input',
              templateOptions: {
                  type: 'number', 
                  label: 'Tiempo minimo para que empiece un riego (segundos)',
              },
              className: 'col',
          },
      ],
  },
    {
    key: 'installation',
    fieldGroupClassName: 'row',
    fieldGroup: [
        {
          key: 'installation_requeriments_software',
          type: 'input',
          templateOptions: {
            label: 'Installation requirements: Software',
          },
          className: 'col',
        },
        {
          key: 'installation_requeriments_hardware',
          type: 'input',
          templateOptions: {
            label: 'Installation requirements: Hardware',
          },
          className: 'col',
        },
   
      ],
    }, 
    {

          key: 'system_status_classification',
          type: 'input',
          templateOptions: {
            label: 'Classification statement attending to SystemStatus definition',
          },
          className: 'col',
        },
        {
          key: 'system_status_definition',
          type: 'input',
          templateOptions: {
            label: 'Characterization statement attending to SystemStatus definition',
          },
          className: 'col',
        },
        {
          key: 'communication_sut',
          type: 'input',
          templateOptions: {
            label: 'Which time between communications is used by the SUT?',
          },
          className: 'col',
        },
        {
          key: 'communication_error',
          type: 'input',
          templateOptions: {
            label: 'Time required to determine a communication error',
          },
          className: 'col',
        },

    {
      template: '<br>',
        },
    {
      key: 'interoperability',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col">Which interoperability protocol implements? Applicable to eny kind of SUT.</h6>',
          className: 'col',
            },
            {
              key: 'interoperability_soap',
              type: 'checkbox',
              templateOptions: {
                label:'SOAP 1.2'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'interoperability_rest',
              type: 'checkbox',
              templateOptions: {
                label:'REST'
              },
              className: 'col',
              defaultValue:false
            },
    ],
    },
    
      {
        template: '<br><h6 class="col">Which ISO 21622 entity types can the SUT control/manage? Applicable to eny kind of SUT</h6><br>',
        className: 'col',
          },
        
    {
      key: 'iso_entitys',
      fieldGroupClassName: 'row',
      fieldGroup: [
      
            {
              key: 'iso_entitys_hys',
              type: 'checkbox',
              templateOptions: {
                label:'HYS'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_ira',
              type: 'checkbox',
              templateOptions: {
                label:'IRA'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_vih',
              type: 'checkbox',
              templateOptions: {
                label:'VIH'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_ihg',
              type: 'checkbox',
              templateOptions: {
                label:'IHG'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_pms',
              type: 'checkbox',
              templateOptions: {
                label:'PMS'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_int',
              type: 'checkbox',
              templateOptions: {
                label:'INT'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_rsv',
              type: 'checkbox',
              templateOptions: {
                label:'RSV'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_sss',
              type: 'checkbox',
              templateOptions: {
                label:'SSS'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_fts',
              type: 'checkbox',
              templateOptions: {
                label:'FTS'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_nbu',
              type: 'checkbox',
              templateOptions: {
                label:'NBU'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_wfs',
              type: 'checkbox',
              templateOptions: {
                label:'WFS'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_npc',
              type: 'checkbox',
              templateOptions: {
                label:'NCP'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'iso_entitys_mih',
              type: 'checkbox',
              templateOptions: {
                label:'MIH'
              },
              className: 'col',
              defaultValue:false
            },
    ],
    },
    {
      template: '<br><h6 class="col">Which ISO 21622 entity types can the SUT control/manage? Applicable to eny kind of SUT:</h6>',
      className: 'col',
        },

        {
          template: '<br><h6 class="col">Propiedades Obligatorias</h6><br>',
          className: 'col',
            },
            {
              key: 'propiedades_obligatorias',
              fieldGroupClassName: 'row',
              fieldGroup: [
        
                    {
                      key: 'ActivityStatus',
                      type: 'checkbox',
                      templateOptions: {
                        label:'ActivityStatus'
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      key: 'SystemStatus',
                      type: 'checkbox',
                      templateOptions: {
                        label:'SystemStatus'
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      key: 'Mode',
                      type: 'checkbox',
                      templateOptions: {
                          label:'Mode'
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      key: 'CumulativeVolumeOut',
                      type: 'checkbox',
                      templateOptions: {
                          label:'CumulativeVolumeOut'
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      key: 'OpeningDegree',
                      type: 'checkbox',
                      templateOptions: {
                          label:'OpeningDegree'
                      },
                      className: 'col',
                      defaultValue:false
                    },
            ],
            },
            {
              template: '<br><h6>Propiedades Opcionales 2 (Energía)</h6><br>',
                },
                {
                  key: 'propiedades_opcionales',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
             
                        {
                          key: 'InstantPower',
                          type: 'checkbox',
                          templateOptions: {
                            label:'InstantPower'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'EnergyConsumption',
                          type: 'checkbox',
                          templateOptions: {
                             label:'EnergyConsumption'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'EntityPerformance',
                          type: 'checkbox',
                          templateOptions: {
                             label:'EntityPerformance'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'PowerOnTime',
                          type: 'checkbox',
                          templateOptions: {
                             label:'PowerOnTime'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'Capacity',
                          type: 'checkbox',
                          templateOptions: {
                             label:'Capacity'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                ],
                },
                {
                  template: '<br><h6>Propiedades Opcionales 3 (Ambiental)</h6><br>',
                    },
            
                {
                  key: 'propiedades_opcionales_2',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
            
                        {
                          key: 'SolarRadiation',
                          type: 'checkbox',
                          templateOptions: {
                            label:'SolarRadiation'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'WindParameters',
                          type: 'checkbox',
                          templateOptions: {
                            label:'WindParameters'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'Rainfall',
                          type: 'checkbox',
                          templateOptions: {
                            label:'Rainfall'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'Temperature',
                          type: 'checkbox',
                          templateOptions: {
                            label:'Temperature'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'RelativeHumidity',
                          type: 'checkbox',
                          templateOptions: {
                            label:'RelativeHumidity'
                          },
                          className: 'col',
                          defaultValue:false
                        },
                ],
                },
                {
                  template: '<br><h6>Otras propiedades</h6><br>',
                    },
    {
      key: 'propiedades_opcionales_3',
      fieldGroupClassName: 'row',
      fieldGroup: [
    
            {
              key: 'InternalFlowOut',
              type: 'checkbox',
              templateOptions: {
                label:'InternalFlowOut'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'InternalFlowIn',
              type: 'checkbox',
              templateOptions: {
                label:'InternalFlowIn'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'PressureOut',
              type: 'checkbox',
              templateOptions: {
                label:'PressureOut'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'PressureIn',
              type: 'checkbox',
              templateOptions: {
                label:'PressureIn'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'SoilWaterContent',
              type: 'checkbox',
              templateOptions: {
                label:'SoilWaterContent'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'SoilWaterPotential',
              type: 'checkbox',
              templateOptions: {
                label:'SoilWaterPotential'
              },
              className: 'col',
              defaultValue:false
            },
    ],
    },
    
    {
      key: 'propiedades_opcionales_4',
      fieldGroupClassName: 'row',
      fieldGroup: [

            {
              key: 'SoilTemperature',
              type: 'checkbox',
              templateOptions: {
                label:'SoilTemperature'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'SoilConductivity',
              type: 'checkbox',
              templateOptions: {
                label:'SoilConductivity'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'DifferentialPressure',
              type: 'checkbox',
              templateOptions: {
                label:'DifferentialPressure'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'WaterAcidity',
              type: 'checkbox',
              templateOptions: {
                label:'WaterAcidity'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'WaterConductivity',
              type: 'checkbox',
              templateOptions: {
                label:'WaterConductivity'
              },
              className: 'col',
              defaultValue:false
            },
    ],
    },
    {
      template: '<br><h6 class="col"> Which types of procedural elements are executable by the SUT?</h6><br>',
      className: 'col',
        },
    {
      key: 'procedural_elements',
      fieldGroupClassName: 'row',
      fieldGroup: [
      
            {
              key: 'procedural_elements_ior1',
              type: 'checkbox',
              templateOptions: {
                label:'IOR1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_ior2',
              type: 'checkbox',
              templateOptions: {
                label:'IOR2'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_ior3',
              type: 'checkbox',
              templateOptions: {
                label:'IOR3'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_por1',
              type: 'checkbox',
              templateOptions: {
                label:'POR1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_por2',
              type: 'checkbox',
              templateOptions: {
                label:'POR2'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_por3',
              type: 'checkbox',
              templateOptions: {
                label:'POR3'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_por4',
              type: 'checkbox',
              templateOptions: {
                label:'POR4'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_por5',
              type: 'checkbox',
              templateOptions: {
                label:'POR5'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_sor1',
              type: 'checkbox',
              templateOptions: {
                label:'SOR1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_igr1',
              type: 'checkbox',
              templateOptions: {
                label:'IGR1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_nbr1',
              type: 'checkbox',
              templateOptions: {
                label:'NBR1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_cpr1',
              type: 'checkbox',
              templateOptions: {
                label:'CPR1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'procedural_elements_iup1',
              type: 'checkbox',
              templateOptions: {
                label:'IUP1'
              },
              className: 'col',
              defaultValue:false
            },
    ],
    },

    {
      template: '<br><h6 class="col"> See the list of acronyms in the 7.5 and 7.6 of the standard. Applicable to eny kind of SUT</h6><br>',
      className: 'col',
        },
    {
      key: 'acronyms',
      fieldGroupClassName: 'row',
      fieldGroup: [
    
            {
              key: 'acronyms_sup1',
              type: 'checkbox',
              templateOptions: {
                label:'SUP1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_pup1',
              type: 'checkbox',
              templateOptions: {
                label:'PUP1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_rup1',
              type: 'checkbox',
              templateOptions: {
                label:'RUP1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_for1',
              type: 'checkbox',
              templateOptions: {
                label:'FOR1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_ftr1',
              type: 'checkbox',
              templateOptions: {
                label:'FTR1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_ftr2',
              type: 'checkbox',
              templateOptions: {
                label:'FTR2'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_ftr3',
              type: 'checkbox',
              templateOptions: {
                label:'FTR3'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_ftr4',
              type: 'checkbox',
              templateOptions: {
                label:'FTR4'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_ftr5',
              type: 'checkbox',
              templateOptions: {
                label:'FTR5'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_fup1',
              type: 'checkbox',
              templateOptions: {
                label:'FUP1'
              },
              className: 'col',
              defaultValue:false
            },
            {
              key: 'acronyms_gup1',
              type: 'checkbox',
              templateOptions: {
                label:'GUP1'
              },
              className: 'col',
              defaultValue:false
            },
    ],
    },
    {
      template: '<br><h6>SUT specifications applicable to subsystems</h6><br>',
      className: 'col',
    },
    {
      key: 'aplicable_subsystems',
      fieldGroupClassName: 'row',
      fieldGroup: [
     
        {
          key: 'aplicable_subsystems_supports_repetitions',
          type: 'checkbox',
          templateOptions: {
            label:'The SUT supports procedural element repetitions?'
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'aplicable_subsystems_restricction_assosiated',
          type: 'checkbox',
          templateOptions: {
            label:'If the SUT supports repetitions, it has any restriccition associated?'
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'aplicable_subsystems_restricction_procedural',
          type: 'checkbox',
          templateOptions: {
            label:'Has the SUT restrictions about the inmediacy in the start date of a procedural element?'
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'aplicable_subsystems_multiple_procedural',
          type: 'checkbox',
          templateOptions: {
            label:'The SUT supports multiple procedural element sending and has a queue management?'
          },
          className: 'col',
          defaultValue:false
        },
   
      ]
      },
      {
        key: 'aplicable_subsystems_2',
        fieldGroupClassName: 'row',
        fieldGroup: [
    
          {
            key: 'aplicable_subsystems_2_volume',
            type: 'checkbox',
            templateOptions: {
              label:'The SUT supports volume monitoring during procedural element execution?'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'aplicable_subsystems_2_pressure',
            type: 'checkbox',
            templateOptions: {
              label:'The SUT supports pressure monitoring during procedural element execution?'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'aplicable_subsystems_2_flow',
            type: 'checkbox',
            templateOptions: {
              label:'The SUT supports flow monitoring during procedural element execution?'
            },
            className: 'col',
            defaultValue:false
          },
        
     
        ]
        },
    {
      template: '<br>',
    },
    {
      key: 'historico_normalizado',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Tipos de históricos normalizados soportados (0 = no   1 = 24   2 = 48   3 = 96)*</h6><br>',
          className: 'col',
        },
        {
          key: 'historico_normalizado_valores',
          type: 'select',
          templateOptions: {
            placeholder: 'Seleccione aquí',
            options: [
              { value: '1', label: '1' },
              { value: '2', label: '2' },
              { value: '3', label: '3' },
              { value: '4', label: '4' },
            ],
          },
        },
        
      ]
      },
      {
        template: '<br>',
      },
      {
        template: '<h6>Which are the restrictions associated to procedural element execution? (Description)</h6><br>',
        className: 'col',
      },
      {
        key: 'restictions_assosiated_input',
        type: 'input',
        templateOptions: {
        },
        className: 'col',
      },
      {
        template: '<br>',
      },
      {
        template: '<h6>Has the SUT owner provide its hardware and software installation documents and user manuals? (Identification of the provided documents)</h6><br>',
        className: 'col',
      },
      {
        key: 'user_manual_input',
        type: 'input',
        templateOptions: {
        },
        className: 'col',
      },
      {
        template: '<br>',
      },

      {
        template: '<h6>The SUT hardware components has its wire connection schema? (Identification of the provided documents)</h6><br>',
        className: 'col',
      },
      {
        key: 'connection_schema_input',
        type: 'input',
        templateOptions: {
        },
        className: 'col',
      },
     
      {
        template: '<br>',
      },
  ]


}

