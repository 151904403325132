
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './views/pages/auth/login/login.component';
import { RegisterComponent } from './views/pages/auth/register/register.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './views/layout/layout.module';
import { BasicElementsComponent } from './views/pages/devices/add-device/basic-elements.component';
import { BasicTableComponent } from './views/pages/devices/view-devices/basic-table.component';
import { DashboardComponent } from './views/pages/dashboard/dashboard.component';
import { AngularJsonFormModule } from 'angular-json-form';
import { ArchwizardModule } from 'angular-archwizard';
import { JsonSchemaComponent } from './views/pages/devices/view-device/setup-device/json-schema.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ViewDeviceComponent } from './views/pages/devices/view-device/view-device.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { TestingComponent } from './views/pages/devices/testing/testing.component';
import { TestComponent } from './views/pages/test/test.component';
import { ViewTestComponent } from './views/pages/test/view-test/view-test.component';
import { NewBatchComponent } from './views/pages/test/new-batch/new-batch.component';
import { TreeViewModule } from "@progress/kendo-angular-treeview";
import { DeviceTestResultComponent } from './views/pages/test/device-test-result/device-test-result.component';
import { FormsModule } from '@angular/forms';
import { UpdateDeviceComponent } from './views/pages/devices/view-device/update-device/update-device.component';
import { InterSetupDeviceComponent } from './views/pages/devices/view-device/inter-setup-device/inter-setup-device.component';
import { initializeKeycloak } from './init/keycloak-init.factory';
import { PdfTestViewComponent } from './views/pages/test/view-test/pdf-test-view/pdf-test-view.component';
import { PrintSettingsComponent } from './views/pages/devices/view-device/print-settings/print-settings.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    BasicElementsComponent,
    BasicTableComponent,
    DashboardComponent,
    JsonSchemaComponent,
    ViewDeviceComponent,
    TestingComponent,
    TestComponent,
    ViewTestComponent,
    NewBatchComponent,
    DeviceTestResultComponent,
    UpdateDeviceComponent,
    InterSetupDeviceComponent,
    PdfTestViewComponent,
    PrintSettingsComponent
  

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    KeycloakAngularModule,
    LayoutModule,
    AngularJsonFormModule,
    ArchwizardModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyBootstrapModule,
    BrowserAnimationsModule,
    NgApexchartsModule,
    TreeViewModule,
    FormsModule,
    

    
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
