import { KeycloakService } from "keycloak-angular";
import { Router } from "@angular/router";
export function initializeKeycloak(keycloak: KeycloakService, router: Router) {
  return async () => {
    await keycloak.init({
      config: {
        url: 'https://tragsa-keycloak.libnamic.eu',
        realm: 'tragsa',
        clientId: 'interoperabilidad-une-318002'
      },
      initOptions: {
        checkLoginIframe: false
      },
      enableBearerInterceptor: true,
    }).then(async (authenticated) => {
      if (authenticated) {
        try {
          const idToken = await keycloak.getKeycloakInstance().idToken;
          if (idToken) { // Asegurarse de que idToken no sea undefined
            localStorage.setItem('id_token', idToken);
          } else {
            console.error('ID token no disponible');
          }
        } catch (error) {
          console.error('Error al obtener el ID token:', error);
        }
      }
    }).catch(error => {
      console.error('Error al inicializar Keycloak:', error);
    });
  };
}

