import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {FormlyFieldConfig} from '@ngx-formly/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api/api.service';
import { AuthService } from 'src/app/services/authService/auth.service';

@Component({
  selector: 'app-json-schema',
  templateUrl: './json-schema.component.html',
  styleUrls: ['./json-schema.component.scss']
})
export class JsonSchemaComponent implements OnInit {
  deviceId: number;
  deviceData: any;
  jsonData:any; 
  token:string

  constructor(private route: ActivatedRoute, private router: Router, private api: ApiService,private authService: AuthService) {
    const routeDeviceId = this.route.snapshot.paramMap.get('deviceId');
    if (routeDeviceId) {
      this.deviceId = Number(routeDeviceId);
    }
  }

 
  ngOnInit(): void {
    this.authService.getKeycloakToken()
    .then(token => {
      this.token = token; 
    })
    this.getDeviceDetails();
  }

  getDeviceDetails() {
    this.api.showDevice(this.deviceId,this.token).subscribe({
      next: (data) => {
        this.deviceData = data; 
        if (this.deviceData && this.deviceData.setup) {
          this.jsonData = JSON.parse(this.deviceData.setup); 
          this.setFormValues(); 
        }
      },
      error: (error) => console.error('Error al obtener detalles del sistema:', error)
    });
  }
  currentStep: number = 0; 

  goToNextStep(wizard: any) {
 
    this.currentStep++; 
    wizard.goToStep(this.currentStep); 
    window.scrollTo(0,0);
    
  }

  goToPreviousStep(wizard: any) {
    if (this.currentStep > 0) {
      this.currentStep--;
      wizard.goToStep(this.currentStep);
      window.scrollTo(0, 0);
    }
  }
  finish() {
    this.onSubmit()
  }

  onSubmit() {
    const combinedJson = {
      ...this.form.value, 
      ...this.form1.value, 
      ...this.form2.value,
      ...this.form3.value,
      ...this.form4.value,
      ...this.form5.value,
      ...this.form6.value,
      ...this.form7.value,
      ...this.form8.value,
      ...this.form9.value
    };

    console.log(combinedJson)
    this.api.setupDevice(this.token,this.deviceId, combinedJson).subscribe({
      next: () => {
      },
      error: error => {
      }
    });
  }

  setFormValues() {
    Object.keys(this.jsonData).forEach(key => {
        if (this.form.contains(key) && this.form.get(key)) {
          this.form.get(key)?.patchValue (this.jsonData[key]);
        } else if (this.form1.contains(key) && this.form1.get(key)) {
          this.form1.get(key)?.patchValue (this.jsonData[key]);
        }
      else if (this.form2.contains(key)&& this.form2.get(key)) {
        this.form2.get(key)?.patchValue (this.jsonData[key]);
      }
      else if (this.form3.contains(key)  && this.form3.get(key)) {
        this.form3.get(key)?.patchValue (this.jsonData[key]);
      }
      else if (this.form4.contains(key) && this.form4.get(key)) {
        this.form4.get(key)?.patchValue (this.jsonData[key]);
      }
      else if (this.form5.contains(key) && this.form5.get(key)) {
        this.form5.get(key)?.patchValue (this.jsonData[key]);
      }
      else if (this.form6.contains(key) && this.form6.get(key)) {
        this.form6.get(key)?.patchValue (this.jsonData[key]);
      }
      else if (this.form7.contains(key) && this.form7.get(key)) {
        this.form7.get(key)?.patchValue (this.jsonData[key]);
      }
      else if (this.form8.contains(key) && this.form8.get(key)) {
        this.form8.get(key)?.patchValue (this.jsonData[key]);
      }
      else if (this.form9.contains(key) && this.form9.get(key)) {
        this.form9.get(key)?.patchValue (this.jsonData[key]);
      }
    });
  }
 
 
  form = new FormGroup({
  });
  model = {};
  fields: FormlyFieldConfig[] = [
    {
      template: '<h3>Configuración inicial</h3><br>',
    },
    {
      key: 'abrir_cerrar_valvula',
      type: 'checkbox',
      templateOptions: {
        label: 'Abrir y cerrar válvulas por orden directa',
       
      },
      defaultValue:false
    },
    {
      key: 'programar_riego_inicio_duracion',
      type: 'checkbox',
      templateOptions: {
        label: 'Programar un riego por momento de inicio y duración',
      },
      defaultValue:false
    },
    {
      key: 'programar_riego_inicio_fin',
      type: 'checkbox',
      templateOptions: {
        label: 'Programar un riego por momento de inicio y momento de fin',
      },
      defaultValue:false
    },
    {
      key: 'programar_riego_duracion_fin',
      type: 'checkbox',
      templateOptions: {
        label: 'Programar un riego por duración y momento de fin',
      },
      defaultValue:false
    },
    {
      key: 'programar_riego_volumen',
      type: 'checkbox',
      templateOptions: {
        label: 'Programar un riego por volumen',
      },
      defaultValue:false
    },
    {
      key: 'programar_riego_inicio_max_duración_max_volumen',
      type: 'checkbox',
      templateOptions: {
        label: 'Programar un riego por momento de inicio, duración máxima y volumen máximo',
      },
      defaultValue:false
    },
    {
      key: 'programacion_periodica',
      type: 'checkbox',
      templateOptions: {
        label: 'Programar un riego periódico (p.e.: todos los días de 6 a 8 horas)',
      },
      defaultValue:false
    },
    {
      key: 'programacion_periodica_anidada',
      type: 'checkbox',
      templateOptions: {
        label: 'Anidar programas periódicos. Nº de niveles (p.e.: “Del 1 de julio al 31 de agosto (nivel 1), los lunes, miércoles y viernes (nivel 2), regar de 8 a 10 y de 20 a 22 horas (nivel 3)”)',
      },
      defaultValue:false
    },
    {
      key: 'numero_niveles',
      type: 'select',
      templateOptions: {
        label: 'Número de Niveles',
        placeholder: 'Selecciona el nivel',
        options: [
          { value: '1', label: '1' },
          { value: '2', label: '2' },
          { value: '3', label: '3' },
        ],
      },
    },
    {
      key: 'configurar_pulsos_valvula',
      type: 'checkbox',
      templateOptions: {
        label: 'Permite configurar los pulsos de disparo de válvula',
      },
      defaultValue:false
    },
   {
    key: 'tipos_valvula',
      type: 'checkbox',
      templateOptions: {
        label: 'Permite predefinir tipos de válvula (p.e: de 6v y 40ms, de 18v y 100ms, ...)'
      },
      defaultValue:false
    },
    {
      key: 'nombre_administrativo_valvula',
      type: 'checkbox',
      templateOptions: {
        label: 'Asignar un nombre administrativo a una válvula (hidrante)'
      },
      defaultValue:false
    },
    {
      key: 'numero_serie_valvula',
      type: 'checkbox',
      templateOptions: {
        label: 'Asignar un número de serie a una válvula'
      },
      defaultValue:false
    },
    {
      key: 'asignar_contador_valvula',
      type: 'checkbox',
      templateOptions: {
        label: 'Asociar una válvula con un contador'
      },
      defaultValue:false
    },
    {
      key: 'asignar_valvulas_multiples_contadores',
      type: 'checkbox',
      templateOptions: {
        label: 'Asociar varias válvulas con un mismo contador.'
      },
      defaultValue:false
    },
    {
      key: 'numero_valvulas',
      type: 'input',
      templateOptions: {
        type: 'number',
        label: '¿Cuantas?',
        placeholder: 'Ingrese un número',
      },
    },
    
    {
      key: 'leer_contador',
      type: 'checkbox',
      templateOptions: {
        label: 'Leer inmediatamente un contador'
      },
      defaultValue:false
    },
    {
      key: 'escribir_contador',
      type: 'checkbox',
      templateOptions: {
        label: 'Escribir inmediatamente un contador'
      },
      defaultValue:false
    },
    {
      key: 'configur_pulso_contador',
      type: 'checkbox',
      templateOptions: {
        label: 'Permite configurar el volumen / pulso de contador'
      },
      defaultValue:false
    },
  {
      key: "definir_tipo_contador",
      type: "checkbox",
      templateOptions: {
        label: "Permite predefinir tipos de contador (p.e: de 0,1 m3/pulso, de 1 m3/pulso, ...)"
      },
      defaultValue:false
    },
    {
      key: "asignar_nombre_contador",
      type: "checkbox",
      templateOptions: {
        label: "Asignar un nombre administrativo a un contador"
      },
      defaultValue:false
    },
    {
      key: "asignar_numero_contador",
      type: "checkbox",
      templateOptions: {
        label: "Asignar un número de serie a un contador"
      },
      defaultValue:false
    },
    {
      key: "entrada_analogica",
      type: "checkbox",
      templateOptions: {
        label: "Leer entrada analógica"
      },
      defaultValue:false
    },
    {
      key: "entrada_analogica_alimentacion",
      type: "checkbox",
      templateOptions: {
        label: "Permite configurar la alimentación del sensor de la entrada analógica"
      },
      defaultValue:false
    },
    {
      key: "convertir_señal_analogica",
      type: "checkbox",
      templateOptions: {
        label: "Convierte la señal de la entrada analógica a unidades de ingeniería (MPa, litros/s, ...)"
      },
      defaultValue:false
    },
    {
      key: "asignar_nombre_entrada",
      type: "checkbox",
      templateOptions: {
        label: "Asignar nombre administrativo a una entrada analógica"
      },
      defaultValue:false
    },
    {
      key: "salida_analogica",
      type: "checkbox",
      templateOptions: {
        label: "Escribir salida analógica"
      },
      defaultValue:false
    }
  ];


  form1 = new FormGroup({});
  model1 = {};
  fields1: FormlyFieldConfig[] = [
    {
      template: '<h3>Elementos específicos que componen el sistema</h3><br>',
    },
    {
      key: 'elementos_centro_control',
      type: 'input',
      templateOptions: {
        label: 'En el centro de control',
      },
     
    },
    {
      key: 'elementos_campo',
      type: 'input',
      templateOptions: {
        label: 'En el campo',
      },
    },
    {
      key: 'elementos_intermedios',
      type: 'input',
      templateOptions: {
        label: 'Elementos intermedios',
      },
    },
    {
      template: '<h4>Se persigue la valoración de:</h4><br>',
    },
    {
      key: 'escalabilidad',
      type: 'input',
      templateOptions: {
        label: 'Escalabilidad',
      },
    },
    {
      template: '<h4>¿Qué elementos compondrían un sistema para controlar sólo 1 hidrante?</h4><br>',
    },
    {
      key: 'uno_centro_control',
      type: 'input',
      templateOptions: {
        label: 'En el centro de control',
      },
    },
    {
      key: 'uno_campo',
      type: 'input',
      templateOptions: {
        label: 'En el campo',
      },
    },
    {
      key: 'uno_elementos_intermedios',
      type: 'input',
      templateOptions: {
        label: 'Elementos intermedios',
      },
    },
    {
      template: '<h4>¿Qué elementos compondrían un sistema para controlar 100 hidrantes?</h4><br>',
    },
    {
      key: 'cien_centro_control',
      type: 'input',
      templateOptions: {
        label: 'En el centro de control',
      },
    },
    {
      key: 'cien_campo',
      type: 'input',
      templateOptions: {
        label: 'En el campo',
      },
    },
    {
      key: 'cien_elementos_intermedios',
      type: 'input',
      templateOptions: {
        label: 'Elementos intermedios',
      },
    },
    {
      template: '<h4>¿Qué elementos compondrían un sistema para controlar 10.000 hidrantes?</h4><br>',
    },
    {
      key: 'mil_centro_control',
      type: 'input',
      templateOptions: {
        label: 'En el centro de control',
      },
    },
    {
      key: 'mil_campo',
      type: 'input',
      templateOptions: {
        label: 'En el campo',
      },
    },
    {
      key: 'mil_elementos_intermedios',
      type: 'input',
      templateOptions: {
        label: 'Elementos intermedios',
      },
    },
    {
      template: '<h4>Reconfigurabilidad</h4><br>',
    },
    {
      key: 'modificaciones_necesarias',
      type: 'textarea',
      templateOptions: {
        label: '¿Qué modificaciones son necesarias para el sistema si se añaden o, en su defecto, se quitan elementos en campo?',
      },
    },
    {
      key: 'modificaciones_neceseraias_check',
      type: 'checkbox',
      templateOptions: {
        label: '¿Es un sistema capaz de detectar la instalación de nuevos equipos de manera automática en el centro de control? (sólo con la instalación del elemento en campo)',
      },
      defaultValue:false
    },
    {
      key: 'observaciones',
      type: 'textarea',
      templateOptions: {
        label: 'Observaciones.',
      },
    },
    {
      key: 'pasos_dar_baja',
      type: 'textarea',
      templateOptions: {
        label: '¿Qué pasos son necesarios para dar de baja un equipo?',
      },
    },
    {
      template: '<h4>Expansiones</h4><br>',
    },
    {
      key: 'modificaciones_ampliacion',
      type: 'textarea',
      templateOptions: {
        label: 'En caso de ampliación considerable de la red de hidrantes, (por ejemplo: duplicar el número de elementos de instalación en campo) ¿qué modificaciones son necesarias realizar en el sistema de telecontrol?',
      },
    },
    {
      template: '<h4>Tolerancia a fallos</h4><br>',
    },
    {
      key: 'impide_funcionamiento',
      type: 'checkbox',
      templateOptions: {
        label: 'En caso de avería de cada uno de los elementos del sistema, ¿Hay alguno que impida por completo o en una gran parte el funcionamiento del sistema?',
        options: [
          { label: 'No', value: 'no' },
          { label: 'Sí', value: 'si' },
        ],
      },
      defaultValue:false
    },
    {
      key: 'impide_funcionamiento_input',
      type: 'textarea',
      templateOptions: {
        label: 'En caso afirmativo, ¿qué repuestos se recomienda tener para la sustitución inmediata?',
      },
      defaultValue:""
    },
    {
      template: '<h4>Referencia de obras instaladas con este sistema</h4><br>',
    },
    {
      key: 'referencia_obras',
      type: 'textarea',
      templateOptions: {
        label: 'Referencia de obras instaladas con este sistema',
        },
        }, 
        ];

        form2 = new FormGroup({});
        model2 = {};
        fields2: FormlyFieldConfig[] = [
          {
            template: '<h3>Especificaciones de la Unidad Remota</h3><br>',
          },
          {
            key: 'modelo',
            type: 'input',
            templateOptions: {
              label: 'Modelo',
            },
          },
          {
            template: '<h5>Especificación del modelo</h5><br>',
          },
          {
            key: 'especificacion_modelo',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'especificacion_modelo_version',
                type: 'input',
                templateOptions: {
                  label: 'Versión',
                },
                className: 'col',
              },
              {
                key: 'especificacion_modelo_variante',
                type: 'input',
                templateOptions: {
                  label: 'Variante',
                },
                className: 'col',
              },
              {
                key: 'especificacion_modelo_firmware',
                type: 'input',
                templateOptions: {
                  label: 'Firmware',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h4>Entorno de funcionamiento de la remota</h4><br>',
          },
          {
            template: '<h5>Temperatura de funcionamiento total</h5><br>',
          },
          {
            key: 'temperatura_funcionamiento_total',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'temperatura_funcionamiento_total_min',
                type: 'input',
                templateOptions: {
                  label: 'Mínimo',
                },
                className: 'col',
              },
              {
                key: 'temperatura_funcionamiento_total_max',
                type: 'input',
                templateOptions: {
                  label: 'Máximo',
                },
                className: 'col',
              },
              {
                key: 'temperatura_funcionamiento_total_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>Temperatura de funcionamiento parcial</h5><br>',
          },
          {
            key: 'temperatura_funcionamiento_parcial',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'temperatura_funcionamiento_parcial_min',
                type: 'input',
                templateOptions: {
                  label: 'Mínimo',
                },
                className: 'col',
              },
              {
                key: 'temperatura_funcionamiento_parcial_max',
                type: 'input',
                templateOptions: {
                  label: 'Máximo',
                },
                className: 'col',
              },
              {
                key: 'temperatura_funcionamiento_parcial_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>Temperatura no destructiva (equipo encendido)</h5><br>',
          },
          {
            key: 'temperatura_no_destructiva',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'temperatura_no_destructiva_min',
                type: 'input',
                templateOptions: {
                  label: 'Mínimo',
                },
                className: 'col',
              },
              {
                key: 'temperatura_no_destructiva_max',
                type: 'input',
                templateOptions: {
                  label: 'Máximo',
                },
                className: 'col',
              },
              {
                key: 'temperatura_no_destructiva_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>Temperatura de almacenamiento (equipo apagado)</h5><br>',
          },
          {
            key: 'temperatura_almacenamiento',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'temperatura_almacenamiento_min',
                type: 'input',
                templateOptions: {
                  label: 'Mínimo',
                },
                className: 'col',
              },
              {
                key: 'temperatura_almacenamiento_max',
                type: 'input',
                templateOptions: {
                  label: 'Máximo',
                },
                className: 'col',
              },
              {
                key: 'temperatura_almacenamiento_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>Humedad relativa (RH)</h5><br>',
          },
          {
            key: 'humedad_relativa',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'humedad_relativa_min',
                type: 'input',
                templateOptions: {
                  label: 'Mínimo',
                },
                className: 'col',
              },
              {
                key: 'humedad_relativa_max',
                type: 'input',
                templateOptions: {
                  label: 'Máximo',
                },
                className: 'col',
              },
              {
                key: 'humedad_relativa_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h4>Fuentes de alimentación</h4><br>',
          },
          {
            key: 'baterias_primarias',
            type: 'checkbox',
            templateOptions: {
              label: 'Baterías primarias'
            },
            defaultValue:false
          },
          {
            key: 'fuentes_alimentacion_primarias',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'fuentes_alimentacion_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_primarias_tipo',
                type: 'input',
                templateOptions: {
                  label: 'Tipo',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_primarias_func',
                type: 'input',
                templateOptions: {
                  label: 'Func.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>(Pilas)</h5><br>',
          },
          {
            key: 'fuentes_alimentacion_primarias_pilas',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'fuentes_alimentacion_primarias_pilas_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_primarias_pilas_tipo',
                type: 'input',
                templateOptions: {
                  label: 'Tipo',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_primarias_pilas_func',
                type: 'input',
                templateOptions: {
                  label: 'Func.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>Consumo de las baterías primarias</h5><br>',
          },
          {
            key: 'fuentes_alimentacion_primarias',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'fuentes_alimentacion_primarias_soporta',
                type: 'input',
                templateOptions: {
                  label: '¿Soporta inversión de polaridad en las fuentes de alimentación?',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_primarias_consumo',
                type: 'input',
                templateOptions: {
                  label: 'Consumo',
                },
                className: 'col',
              },
             
            ],
          },
          {
            key: 'baterias_secundarias',
            type: 'checkbox',
            templateOptions: {
              label: 'Baterías secundarias'
            },
            defaultValue:false
          },
          {
            key: 'fuentes_alimentacion',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'fuentes_alimentacion_secundarias_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_secundarias_tipo',
                type: 'input',
                templateOptions: {
                  label: 'Tipo',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_secundarias_func',
                type: 'input',
                templateOptions: {
                  label: 'Func.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>(Recargables)</h5><br>',
          },
          {
            key: 'fuentes_alimentacion_secundarias_pilas',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'fuentes_alimentacion_secundarias_pilas_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_secundarias_pilas_tipo',
                type: 'input',
                templateOptions: {
                  label: 'Tipo',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_secundarias_pilas_func',
                type: 'input',
                templateOptions: {
                  label: 'Func.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>Consumo de las baterías secundarias</h5><br>',
          },
          {
            key: 'fuentes_alimentacion_secundarias',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'fuentes_alimentacion_secundarias_soporta',
                type: 'input',
                templateOptions: {
                  label: '¿Soporta inversión de polaridad en las fuentes de alimentación?',
                },
                className: 'col',
              },
              {
                key: 'fuentes_alimentacion_secundarias_consumo',
                type: 'input',
                templateOptions: {
                  label: 'Consumo',
                },
                className: 'col',
              },
            ],
          },
          {
            key: 'baterias_otras',
            type: 'checkbox',
            templateOptions: {
              label: 'Otras'
            },
            defaultValue:false
          },
          {
            key: 'baterias_otras_input',
            type: 'input',
            templateOptions: {
              label: 'Nombre de la fuente de alimentación',
            },
          },
          {
            key: 'baterias_otras_alimentacion',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'baterias_otras_alimentacion_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
              {
                key: 'baterias_otras_alimentacion_tipo',
                type: 'input',
                templateOptions: {
                  label: 'Tipo',
                },
                className: 'col',
              },
              {
                key: 'baterias_otras_alimentacion_func',
                type: 'input',
                templateOptions: {
                  label: 'Tipo',
                },
                className: 'col',
              },
            ],
          },
          {
            key: 'baterias_otras_alimentacion_tipado',
            type: 'input',
            templateOptions: {
              label: 'Clasificación de la fuente de alimentación',
            },
          },
          {
            key: 'baterias_otras_alimentacion_pilas',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'baterias_otras_alimentacion_pilas_ud',
                type: 'input',
                templateOptions: {
                  label: 'Ud.',
                },
                className: 'col',
              },
              {
                key: 'baterias_otras_alimentacion_pilas_tipo',
                type: 'input',
                templateOptions: {
                  label: 'Tipo',
                },
                className: 'col',
              },
              {
                key: 'baterias_otras_alimentacion_pilas_func',
                type: 'input',
                templateOptions: {
                  label: 'Func.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>Consumo de la otra fuente de alimentación</h5><br>',
          },
          {
            key: 'consumo_baterias_otras_alimentacion',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'consumo_baterias_otras_alimentacion_soporta',
                type: 'input',
                templateOptions: {
                  label: '¿Soporta inversión de polaridad en las fuentes de alimentación?',
                },
                className: 'col',
              },
              {
                key: 'consumo_baterias_otras_alimentacion_consumo',
                type: 'input',
                templateOptions: {
                  label: 'Consumo',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>¿Qué elementos de obra se necesitan en cada Terminal remoto (Paneles solares, mástiles, etc)? Especificar:</h5><br>',
          },
          {
            key: 'referencia_obras_remoto',
            type: 'textarea',
          }, 
           {
             template: '<h5>¿Qué otros elementos se necesitan en el conjunto de la instalación (Acometidas eléctricas, repetidores, licencias administrativas, casetas prefabricadas, torretas, etc)? Especificar: </h5><br>',
           },
          {
             key: 'referencia_obras_elementos',
             type: 'textarea',
          }, 
          {
            template: '<h5>Observaciones</h5><br>',
          },
         {
            key: 'referencia_obras_observaciones',
            type: 'textarea',
         }, 

         ];



         form3 = new FormGroup({});
         model3 = {};
         fields3: FormlyFieldConfig[] = [
          {
            template: '<h3>Componentes de la remota</h3><br>',
          },

          {
            template: '<h5>Especificar número de conectores, características de su sistema de alimentación, cada una de las placas que consta el equipo (placa de alimentación, placa de comunicación…)</h5><br>',
          },

          {
            key: 'componentes_remotos_primero',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'componentes_remotos_primero_1',
                type: 'input',
                templateOptions: {
                  label: '1.',
                },
                className: 'col',
              },
              {
                key: 'componentes_remotos_primero_2',
                type: 'input',
                templateOptions: {
                  label: '2.',
                },
                className: 'col',
              },
              {
                key: 'componentes_remotos_primero_3',
                type: 'input',
                templateOptions: {
                  label: '3.',
                },
                className: 'col',
              },
            ],
          },
          {
            key: 'componentes_remotos_segundo',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'componentes_remotos_segundo_1',
                type: 'input',
                templateOptions: {
                  label: '4.',
                },
                className: 'col',
              },
              {
                key: 'componentes_remotos_segundo_2',
                type: 'input',
                templateOptions: {
                  label: '5.',
                },
                className: 'col',
              },
              {
                key: 'componentes_remotos_segundo_3',
                type: 'input',
                templateOptions: {
                  label: '6.',
                },
                className: 'col',
              },
            ],
          }, 
          {
            key: 'componentes_remotos_tercero',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'componentes_remotos_tercero_1',
                type: 'input',
                templateOptions: {
                  label: '7.',
                },
                className: 'col',
              },
              {
                key: 'componentes_remotos_tercero_2',
                type: 'input',
                templateOptions: {
                  label: '8.',
                },
                className: 'col',
              },
              {
                key: 'componentes_remotos_tercero_3',
                type: 'input',
                templateOptions: {
                  label: '9.',
                },
                className: 'col',
              },
            ],
          },
          {
            key: 'componentes_remotos_cuarto',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'componentes_remotos_cuarto_1',
                type: 'input',
                templateOptions: {
                  label: '10.',
                },
                className: 'col',
              },
              {
                key: 'componentes_remotos_cuarto_2',
                type: 'input',
                templateOptions: {
                  label: '11.',
                },
                className: 'col',
              },
              {
                key: 'componentes_remotos_cuarto_3',
                type: 'input',
                templateOptions: {
                  label: '12.',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h5>Entradas / Salidas</h5><br>',
          },
          {
            key: 'salidas_EV',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'salidas_EV_1',
                type: 'input',
                templateOptions: {
                  label: 'Nº De salidas de EV',
                },
                className: 'col',
              },
              {
                key: 'salidas_EV_2',
                type: 'input',
                templateOptions: {
                  label: 'ampliable hasta',
                },
                className: 'col',
              },
              {
                key: 'salidas_EV_3',
                type: 'input',
                templateOptions: {
                  label: 'en módulos de',
                },
                className: 'col',
              },
            ],
          },
          {
            key: 'entradas_contador',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'entradas_contador_1',
                type: 'input',
                templateOptions: {
                  label: 'Nº De entradas de contador',
                },
                className: 'col',
              },
              {
                key: 'entradas_contador_2',
                type: 'input',
                templateOptions: {
                  label: 'ampliable hasta',
                },
                className: 'col',
              },
              {
                key: 'entradas_contador_3',
                type: 'input',
                templateOptions: {
                  label: 'en módulos de',
                },
                className: 'col',
              },
            ],
          },
          {
            key: 'salidas_digitales',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'salidas_digitales_1',
                type: 'input',
                templateOptions: {
                  label: 'Nº De salidas digitales',
                },
                className: 'col',
              },
              {
                key: 'salidas_digitales_2',
                type: 'input',
                templateOptions: {
                  label: 'ampliable hasta',
                },
                className: 'col',
              },
              {
                key: 'salidas_digitales_3',
                type: 'input',
                templateOptions: {
                  label: 'en módulos de',
                },
                className: 'col',
              },
            ],
          },
          {
            key: 'entradas_digitales',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'entradas_digitales_1',
                type: 'input',
                templateOptions: {
                  label: 'Nº De entradas digitales',
                },
                className: 'col',
              },
              {
                key: 'entradas_digitales_2',
                type: 'input',
                templateOptions: {
                  label: 'ampliable hasta',
                },
                className: 'col',
              },
              {
                key: 'entradas_digitales_3',
                type: 'input',
                templateOptions: {
                  label: 'en módulos de',
                },
                className: 'col',
              },
            ],
          },
          {
            key: 'salidas_analogicas',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'salidas_analogicas_1',
                type: 'input',
                templateOptions: {
                  label: 'Nº De salidas analógicas',
                },
                className: 'col',
              },
              {
                key: 'salidas_analogicas_2',
                type: 'input',
                templateOptions: {
                  label: 'ampliable hasta',
                },
                className: 'col',
              },
              {
                key: 'salidas_analogicas_3',
                type: 'input',
                templateOptions: {
                  label: 'en módulos de',
                },
                className: 'col',
              },
            ],
          },
          {
            key: 'entradas_analogicas',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'entradas_analogicas_1',
                type: 'input',
                templateOptions: {
                  label: 'Nº De entradas analógicas',
                },
                className: 'col',
              },
              {
                key: 'entradas_analogicas_2',
                type: 'input',
                templateOptions: {
                  label: 'ampliable hasta',
                },
                className: 'col',
              },
              {
                key: 'entradas_analogicas_3',
                type: 'input',
                templateOptions: {
                  label: 'en módulos de',
                },
                className: 'col',
              },
            ],
          },
          {
            template: '<h6>¿El firmware se puede reprogramar desde un sitio remoto?</h6><br>',
          },
          {
            key: 'firmware_reprogramar_remoto',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'firmware_reprogramar_remoto_si',
                type: 'checkbox',
                templateOptions: {
                  label: 'Si',
                },
                className: 'col',
                defaultValue:false
              },
              {
                key: 'firmware_reprogramar_remoto_no',
                type: 'checkbox',
                templateOptions: {
                  label: 'No',
                },
                className: 'col',
                defaultValue:false
              },
            ],
          },
          {
            template: '<h6>¿Se puede reprogramar remotamente el firmware de todas las remotas a la vez?</h6><br>',
          },
          {
            key: 'firmware_reprogramar_todas_remoto',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'firmware_reprogramar_todas_remoto_si',
                type: 'checkbox',
                templateOptions: {
                  label: 'Si',
                },
                className: 'col',
                defaultValue:false
              },
              {
                key: 'firmware_reprogramar_todas_remoto_no',
                type: 'checkbox',
                templateOptions: {
                  label: 'No',
                },
                className: 'col',
                defaultValue:false
              },
            ],
          },
          {
            template: '<h6>¿Cuánto tiempo se tarda en cambiar el firmware en los equipos? Expresar en segundos.</h6><br>',
          },
          {
            key: 'tiempo_cambiar_firmware',
            type: 'input',
          
          },
          {
            template: '<h6>¿Dispone la remota de algún indicador luminoso para evaluar el estado de funcionamiento de la misma?</h6><br>',
          },
          {
            key: 'firmware_remoto_indicador',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'firmware_remoto_indicador_si',
                type: 'checkbox',
                templateOptions: {
                  label: 'Si',
                },
                className: 'col',
                defaultValue:false
              },
              {
                key: 'firmware_remoto_indicador_no',
                type: 'checkbox',
                templateOptions: {
                  label: 'No',
                },
                className: 'col',
                defaultValue:false
              },
            ],
          },
          {
            template: '<h6>¿Se puede hacer depuración desde un sitio remoto?</h6><br>',
          },
          {
            key: 'firmware_remoto_depuracion',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'firmware_remoto_depuracion_si',
                type: 'checkbox',
                templateOptions: {
                  label: 'Si',
                },
                className: 'col',
                defaultValue:false
              },
              {
                key: 'firmware_remoto_depuracion_no',
                type: 'checkbox',
                templateOptions: {
                  label: 'No',
                },
                className: 'col',
                defaultValue:false
              },
            ],
          },
          {
            template: '<h6>¿La remota hace un auto-test al arrancar?</h6><br>',
          },
          {
            key: 'firmware_remoto_test',
            fieldGroupClassName: 'row',
            fieldGroup: [
              {
                key: 'firmware_remoto_test_si',
                type: 'checkbox',
                templateOptions: {
                  label: 'Si',
                },
                className: 'col',
                defaultValue:false
              },
              {
                key: 'firmware_remoto_test_no',
                type: 'checkbox',
                templateOptions: {
                  label: 'No',
                },
                className: 'col',
                defaultValue:false
              },
            ],
          },
          {
            template: '<h5>Observaciones</h5><br>',
          },
          {
            key: 'firmware_observaciones',
            type: 'textarea',
          },
         ]

         
        form4 = new FormGroup({});
        model4 = {};
        fields4: FormlyFieldConfig[] = [

          {
            template: '<h3>Consumo de la alimentación</h3><br>',
          },
          {
            template: '<h6>Consumo alimentado desde:</h6><br>',
          },
          {
            key: 'consumo_alimentacion',
            type: 'input',
          
          },
          {
            template: '<br>',
          },
  {
  key: 'alimentacion_remota',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">Abr.</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Alimentación remota</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Mínimo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Nominal</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Máximo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Ud.</h6><br>',
      className: 'col',
    },

    
  ],
}, 
{
  key: 'alimentacion_remota_vop',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Vop</p>',
      className: 'col',
    },
    {
      template: '<p class="col">Funcionamiento completo</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vop_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vop_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vop_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vop_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_remota_vpar',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Vpar</p>',
      className: 'col',
    },
    {
      template: '<p class="col">Funcionamiento parcial</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vpar_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vpar_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vpar_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vpar_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_remota_vlim',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Vlim</p>',
      className: 'col',
    },
    {
      template: '<p class="col">Tensión no destructiva</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vlim_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vlim_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vlim_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_remota_vlim_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  template: '<br>',
},
{
key: 'alimentacion_vop_minima',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">Consumo a VOP mínimo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">NA</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Mínimo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Nominal</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Máximo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Ud.</h6><br>',
      className: 'col',
    }, 
  ],
},
{
  key: 'alimentacion_vop_minima_reposo',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">En reposo</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_reposo_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_reposo_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_reposo_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_reposo_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_reposo_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_minima_activo',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Modo activo</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_activo_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_activo_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_activo_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_activo_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_activo_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_minima_comunicando',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Comunicando</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_comunicando_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_comunicando_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_comunicando_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_comunicando_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_comunicando_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_minima_valvula',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Activando válvula</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_valvula_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_valvula_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_valvula_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_valvula_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_valvula_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_minima_analogica',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Midiendo analógica</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_analogica_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_analogica_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_analogica_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_analogica_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_analogica_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_minima_pulsos',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Contando pulsos</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_pulsos_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_pulsos_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_pulsos_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_pulsos_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_minima_pulsos_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 

{
  template: '<br>',
},
{
key: 'alimentacion_vop_maxima',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">Consumo a VOP máximo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">NA</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Mínimo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Nominal</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Máximo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Ud.</h6><br>',
      className: 'col',
    }, 
  ],
},
{
  key: 'alimentacion_vop_maxima_reposo',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">En reposo</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_reposo_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_reposo_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_reposo_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_reposo_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_reposo_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_maxima_activo',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Modo activo</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_activo_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_activo_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_activo_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_activo_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_activo_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_maxima_comunicando',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Comunicando</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_comunicando_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_comunicando_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_comunicando_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_comunicando_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_comunicando_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_maxima_valvula',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Activando válvula</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_valvula_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_valvula_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_valvula_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_valvula_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_valvula_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_maxima_analogica',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Midiendo analógica</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_analogica_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_analogica_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_analogica_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_analogica_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_analogica_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_maxima_pulsos',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Contando pulsos</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_pulsos_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_pulsos_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_pulsos_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_pulsos_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_maxima_pulsos_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  template: '<br>',
},
{
key: 'alimentacion_vop_nominal',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">Consumo a VOP nominal</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">NA</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Mínimo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Nominal</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Máximo</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Ud.</h6><br>',
      className: 'col',
    }, 
  ],
},
{
  key: 'alimentacion_vop_nominal_reposo',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">En reposo</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_reposo_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_reposo_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_reposo_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_reposo_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_reposo_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_nominal_activo',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Modo activo</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_activo_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_activo_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_activo_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_activo_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_activo_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_nominal_comunicando',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Comunicando</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_comunicando_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_comunicando_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_comunicando_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_comunicando_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_comunicando_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_nominal_valvula',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Activando válvula</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_valvula_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_valvula_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_valvula_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_valvula_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_valvula_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_nominal_analogica',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Midiendo analógica</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_analogica_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_analogica_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_analogica_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_analogica_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_analogica_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  key: 'alimentacion_vop_nominal_pulsos',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<p class="col">Contando pulsos</p>',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_pulsos_na',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_pulsos_minima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_pulsos_nominal',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_pulsos_maxima',
      type: 'input',
      className: 'col',
    },
    {
      key: 'alimentacion_vop_nominal_pulsos_ud',
      type: 'input',
      className: 'col',
    }, 
  ],
}, 
{
  template: '<br><h5>Descripción del funcionamiento parcial</h5><br>',
},
{
  key: 'descripcion_funcionamiento_parcial',
  type: 'textarea',
},
{
  template: '<h5>Observaciones</h5><br>',
},
{
  key: 'firmware_observaciones',
  type: 'textarea',
},
  ]

  form5 = new FormGroup({});
  model5 = {};
  fields5: FormlyFieldConfig[] = [
    {
      template: '<h3>Salida de electroválvula</h3><br>',
    },
    {
      key: 'salida_electrovalvula',
      fieldGroupClassName: 'row',
      fieldGroup: [
    {
      template: '<h6 class="col">Conexión</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Carga condensador</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Intensidad máxima en EV</h6><br>',
      className: 'col',
    }, 
  ],
},

{
  key: 'salida_electrovalvula_carga_dos',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'salida_electrovalvula_carga_dos_hilos',
      type: 'checkbox',
      templateOptions: {
        label: '2 Hilos',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'salida_electrovalvula_carga_dos_hilos_condensador',
      type: 'checkbox',
      templateOptions: {
        label: 'No',
      },
      className: 'col',
      defaultValue:false
    },
 
    {
      template: '<h6 class="col"></h6>',
      className: 'col',
    },
  ],
},
{
  key: 'salida_electrovalvula_carga_tres_positivo',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'salida_electrovalvula_carga_tres_hilos_positivo',
      type: 'checkbox',
      templateOptions: {
        label: '3 Hilos positivo común',
      },
      className: 'col',
      defaultValue:false
    },
    {
      
      key: 'salida_electrovalvula_carga_tres_hilos_positivo_input',
      type: 'input',
      templateOptions: {
        placeholder: 'Si, de ... uF ',
      },
      className: 'col',
      
    },
 
    {
      key: 'salida_electrovalvula_carga_tres_hilos_positivo_intensidad',
      type: 'input',
      templateOptions: {
        placeholder: 'A ',
      },
      className: 'col',
    },
  ],
},

{
  key: 'salida_electrovalvula_carga_tres_negativo',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'salida_electrovalvula_carga_tres_hilos_negativo',
      type: 'checkbox',
      templateOptions: {
        label: '3 Hilos negativo común',
      },
      className: 'col',
      defaultValue:false
    },
    {
      template: '<h6 class="col"></h6>',
      className: 'col',
    },
  ],
},
{
  template: '<br><h4>Tensión de disparo configurable</h4><br>',
},

{
  key: 'tension_disparo_1',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'tension_disparo_no',
      type: 'checkbox',
      templateOptions: {
        label: 'No',
      },
      className: 'col',
      defaultValue:false
    },
    {  
      key: 'tension_disparo_no_input',
      type: 'input',
      templateOptions: {
        placeholder: 'Vnom ',
      },
      className: 'col',
    },
{
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
{
  template: '<h6 class="col"></h6>',
  className: 'col',
},
{
  template: '<h6 class="col"></h6>',
  className: 'col',
},
{
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
],
},

{
  key: 'tension_disparo_2',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'tension_disparo_si',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {  
      key: 'tension_disparo_vmin',
      type: 'input',
      templateOptions: {
        placeholder: 'Vmin ',
      },
      className: 'col',
    },
    {  
      key: 'tension_disparo_vmax',
      type: 'input',
      templateOptions: {
        placeholder: 'Vmax ',
      },
      className: 'col',
    },
    {  
      key: 'tension_disparo_pasos',
      type: 'input',
      templateOptions: {
        placeholder: 'Pasos ',
      },
      className: 'col',
    },
    {
      key: 'tension_disparo_sw',
      type: 'checkbox',
      templateOptions: {
        label: 'Sw',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'tension_disparo_hw',
      type: 'checkbox',
      templateOptions: {
        label: 'Hw',
      },
      className: 'col',
      defaultValue:false
    },
],
},

{
  template: '<br><h4>Tensión de disparo configurable</h4><br>',
},

{
  key: 'tension_disparo_1_1',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'tension_disparo_no_1',
      type: 'checkbox',
      templateOptions: {
        label: 'No',
      },
      className: 'col',
      defaultValue:false
    },
    {  
      key: 'tension_disparo_no_1_input',
      type: 'input',
      templateOptions: {
        placeholder: 'Vnom ',
      },
      className: 'col',
    },
{
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
{
  template: '<h6 class="col"></h6>',
  className: 'col',
},
{
  template: '<h6 class="col"></h6>',
  className: 'col',
},
{
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
],
},

{
  key: 'tension_disparo_2_1',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'tension_disparo_si',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {  
      key: 'tension_disparo_vmin_1',
      type: 'input',
      templateOptions: {
        placeholder: 'Vmin ',
      },
      className: 'col',
    },
    {  
      key: 'tension_disparo_vmax_1',
      type: 'input',
      templateOptions: {
        placeholder: 'Vmax ',
      },
      className: 'col',
    },
    {  
      key: 'tension_disparo_pasos_1',
      type: 'input',
      templateOptions: {
        placeholder: 'Pasos ',
      },
      className: 'col',
    },
    {
      key: 'tension_disparo_sw_1',
      type: 'checkbox',
      templateOptions: {
        label: 'Sw',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'tension_disparo_hw_1',
      type: 'checkbox',
      templateOptions: {
        label: 'Hw',
      },
      className: 'col',
      defaultValue:false
    },
],
},
{
  template: '<br>',

}, 

{
  key: 'configuracion_tension_disparo_1',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">Permite cortocircuito</h6>',
      className: 'col',
    }, 
    {
      template: '<h6 class="col">Genera alarma</h6>',
      className: 'col',
    },
{
  template: '<h6 class="col">Nivel configurable</h6>',
  className: 'col',
}, 
{
  template: '<h6 class="col">Mínimo</h6>',
  className: 'col',
},
{
  template: '<h6 class="col">Máximo</h6>',
  className: 'col',
},
{
  template: '<h6 class="col">Pasos</h6><br>',
  className: 'col',
}, 
],
},


{
  key: 'configuracion_tension_disparo_1',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'configuracion_tension_disparo_1_cortocircuito',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'configuracion_tension_disparo_1_alarma',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'configuracion_tension_disparo_1_nivel',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },

    {  
      key: 'configuracion_tension_disparo_1_minimo',
      type: 'input',
      templateOptions: {
        placeholder: 'Mín. ',
      },
      className: 'col',
    },
    {  
      key: 'configuracion_tension_disparo_1_maximo',
      type: 'input',
      templateOptions: {
        placeholder: 'Máx. ',
      },
      className: 'col',
    }, 
    {  
      key: 'configuracion_tension_disparo_1_pasos',
      type: 'input',
      templateOptions: {
        placeholder: 'Pasos ',
      },
      className: 'col',
    },
],
},

{
  template: '<br>',

}, 

{
  key: 'configuracion_tension_disparo_2',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">Permite cortocircuito</h6>',
      className: 'col',
    }, 
    {
      template: '<h6 class="col">Genera alarma</h6>',
      className: 'col',
    },
{
  template: '<h6 class="col">Nivel configurable</h6>',
  className: 'col',
}, 
{
  template: '<h6 class="col">Mínimo</h6>',
  className: 'col',
},
{
  template: '<h6 class="col">Máximo</h6>',
  className: 'col',
},
{
  template: '<h6 class="col">Pasos</h6><br>',
  className: 'col',
}, 
],
},


{
  key: 'configuracion_tension_disparo_2',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'configuracion_tension_disparo_2_cortocircuito',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'configuracion_tension_disparo_2_alarma',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'configuracion_tension_disparo_2_nivel',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },

    {  
      key: 'configuracion_tension_disparo_2_minimo',
      type: 'input',
      templateOptions: {
        placeholder: 'Mín. ',
      },
      className: 'col',
    },
    {  
      key: 'configuracion_tension_disparo_2_maximo',
      type: 'input',
      templateOptions: {
        placeholder: 'Máx. ',
      },
      className: 'col',
    }, 
    {  
      key: 'configuracion_tension_disparo_2_pasos',
      type: 'input',
      templateOptions: {
        placeholder: 'Pasos ',
      },
      className: 'col',
    },
],
},

{
  template: '<br>',

}, 
{
  key: 'configuracion_electrovalvula',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col"></h6>',
      className: 'col',
    }, 

    {
      template: '<h6 class="col">Se puede asociar con </h6>',
      className: 'col',
    }, 
    {
      template: '<h6 class="col">Generar alarma ASA</h6>',
      className: 'col',
    },
    {
      template: '<h6 class="col">Generar alarma CCA</h6>',
      className: 'col',
    }, 
],
},
{
  template: '<br>',

}, 
{
  key: 'configuracion_electrovalvula_contador',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">Contador </h6>',
      className: 'col',
    }, 
    {
      key: 'configuracion_electrovalvula_contador_asociar',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
        },
    {
      key: 'configuracion_electrovalvula_contador_alarma_asa',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'configuracion_electrovalvula_contador_alarma_cca',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
],
},
{
  key: 'configuracion_electrovalvula_dpa',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">DPA</h6>',
      className: 'col',
    }, 
    {
      key: 'configuracion_electrovalvula_dpa_asociar',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'configuracion_electrovalvula_dpa_alarma_asa',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'configuracion_electrovalvula_dpa_alarma_cca',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
],
},
{
  key: 'configuracion_electrovalvula_dfc',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">DFC</h6>',
      className: 'col',
    }, 
    {
      key: 'configuracion_electrovalvula_dfc_asociar',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'configuracion_electrovalvula_dfc_alarma_asa',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'configuracion_electrovalvula_dfc_alarma_cca',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
],
},
{
  template: '<h5>Observaciones</h5><br>',
},
{
  key: 'firmware_observaciones',
  type: 'textarea',
},
  ]

  form6 = new FormGroup({});
  model6 = {};
  fields6: FormlyFieldConfig[] = [

    {
      template: '<h3>Entrada de contador</h3><br><br>',
    },
  
    {
      key: 'entrada_contador_conexion',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col">Conexión</h6>',
          className: 'col',
        }, 
        {
          key: 'entrada_contador_conexion_hilos',
          type: 'checkbox',
          templateOptions: {
            label: '2 hilos contacto libre',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_conexion_otra',
          type: 'checkbox',
          templateOptions: {
            label: 'Otra',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_conexion_otra_input',
          type: 'input',
          templateOptions: {
            placeholder: 'Otra',
          },
          className: 'col',
        }, 
    ],
    },

    {
      template: '<h6>Alimentación</h6><br>',
    },

    {
      key: 'entrada_contador_alimentacion',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'entrada_contador_alimentacion_siempre',
          type: 'checkbox',
          templateOptions: {
            label: 'Siempre',
          },
          className: 'col',
          defaultValue:false
        },
    ],
    },
    {
      key: 'entrada_contador_alimentacion_2',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'entrada_contador_alimentacion_antes',
          type: 'checkbox',
          templateOptions: {
            label: 'Antes de la muestra con tiempo',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_alimentacion_fijo',
          type: 'checkbox',
          templateOptions: {
            label: 'Fijo',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_alimentacion_fijo_input',
          type: 'input',
          templateOptions: {
          },
          className: 'col',
        },
       {
      template: '<h6 class="col"></h6>',
      className: 'col',
      },
      {
      template: '<h6 class="col"></h6>',
      className: 'col',
    }, 
    ],
    },
    {
      key: 'entrada_contador_alimentacion_3',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col"></h6>',
          className: 'col',
            },
        {
          key: 'entrada_contador_alimentacion_configurable',
          type: 'checkbox',
          templateOptions: {
            label: 'Configurable',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_alimentacion_configurable_desde',
          type: 'input',
          templateOptions: {
            placeholder:'Desde',
          },
          className: 'col',
        },
        {
          key: 'entrada_contador_alimentacion_configurable_hasta',
          type: 'input',
          templateOptions: {
            placeholder:'Hasta',
          },
          className: 'col',
        },
        {
          key: 'entrada_contador_alimentacion_configurable_pasos',
          type: 'input',
          templateOptions: {
            placeholder:'Pasos',
          },
          className: 'col',
        },
 
    ],
    },
    {
      template: '<br>',
      
    },  
    {
      key: 'entrada_contador_configuración_caudal',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col">Calcula caudal</h6>',
          className: 'col',
        }, 
        {
          template: '<h6 class="col">Genera alarma</h6>',
          className: 'col',
        }, 
        {
          template: '<h6 class="col">Nivel configurable</h6>',
          className: 'col',
        }, 
        {
          template: '<h6 class="col">Mínimo</h6>',
          className: 'col',
        }, 
        {
          template: '<h6 class="col">Máximo</h6>',
          className: 'col',
        }, 
        {
          template: '<h6 class="col">Pasos</h6>',
          className: 'col',
        },  
    ],
    },
    {
      template: '<br>',
      
    },  

    {
      key: 'entrada_contador_configuración_caudal_2',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'entrada_contador_configuración_caudal',
          type: 'checkbox',
          templateOptions: {
            label: 'Si',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_configuración_alarma',
          type: 'checkbox',
          templateOptions: {
            label: 'Si',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_configuración_configurable',
          type: 'checkbox',
          templateOptions: {
            label: 'Si',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_configuración_configurable_min',
          type: 'input',
          templateOptions: {
          },
          className: 'col',
        },
        {
          key: 'entrada_contador_configuración_configurable_max',
          type: 'input',
          templateOptions: {
          },
          className: 'col',
        },
        {
          key: 'entrada_contador_configuración_configurable_pasos',
          type: 'input',
          templateOptions: {
          },
          className: 'col',
        },
 
    ],
    },
    
    
    {
      key: 'entrada_contador_configuración_alimentacion',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col">Lleva alimentación</h6>',
          className: 'col',
        }, 
        {
          template: '<h6 class="col">Soporta cortocircuito</h6>',
          className: 'col',
        }, 
        {
          template: '<h6 class="col">Genera alarma</h6>',
          className: 'col',
        }, 
        {
          template: '<h6 class="col">Nivel configurable</h6>',
          className: 'col',
        }, 
    
    ],
    },
    {
      template: '<br>',
      
    },  

    {
      key: 'entrada_contador_configuración_alimentacion_2',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'entrada_contador_configuración_alimentacion_check',
          type: 'checkbox',
          templateOptions: {
            label: 'Si',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_configuración_alimentacion_cortocircuito',
          type: 'checkbox',
          templateOptions: {
            label: 'Si',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_configuración_alarma',
          type: 'checkbox',
          templateOptions: {
            label: 'Si',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_configuración_nivel',
          type: 'checkbox',
          templateOptions: {
            label: 'Si',
          },
          className: 'col',
          defaultValue:false
        },     
 
    ],
    },
    {
      template: '<br><br>',
      
    },  

    {
      key: 'entrada_contador_muestras',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col">Tiempo entre muestras</h6>',
          className: 'col',
          },
        {
          key: 'entrada_contador_muestras_fijo',
          type: 'checkbox',
          templateOptions: {
            label: 'Fijo',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_muestras_fijo_input',
          type: 'input',
          templateOptions: {
          },
          className: 'col',
        },
       {
      template: '<h6 class="col"></h6>',
      className: 'col',
      },
      {
      template: '<h6 class="col"></h6>',
      className: 'col',
    }, 
    ],
    },
    {
      key: 'entrada_contador_muestras_2',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col"></h6>',
          className: 'col',
            },
        {
          key: 'entrada_contador_muestras_configurable',
          type: 'checkbox',
          templateOptions: {
            label: 'Configurable',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_contador_muestras_configurable_desde',
          type: 'input',
          templateOptions: {
            placeholder:'Desde',
          },
          className: 'col',
        },
        {
          key: 'entrada_contador_muestras_configurable_hasta',
          type: 'input',
          templateOptions: {
            placeholder:'Hasta',
          },
          className: 'col',
        },
        {
          key: 'entrada_contador_muestras_configurable_pasos',
          type: 'input',
          templateOptions: {
            placeholder:'Pasos',
          },
          className: 'col',
        },
 
    ],
    },
  
  {
    key: 'entrada_contador_pulsos',
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        template: '<h6 class="col">Filtrado de pulsos</h6>',
        className: 'col',
        },
      {
        key: 'entrada_contador_pulsos_fijo',
        type: 'checkbox',
        templateOptions: {
          label: 'Fijo',
        },
        className: 'col',
        defaultValue:false
      },
      {
        key: 'entrada_contador_pulsos_fijo_input',
        type: 'input',
        templateOptions: {
        },
        className: 'col',
      },
     {
    template: '<h6 class="col"></h6>',
    className: 'col',
    },
    {
    template: '<h6 class="col"></h6>',
    className: 'col',
  }, 
  ],
  },
  {
    key: 'entrada_contador_muestras_2',
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        template: '<h6 class="col"></h6>',
        className: 'col',
          },
      {
        key: 'entrada_contador_pulsos_configurable',
        type: 'checkbox',
        templateOptions: {
          label: 'Configurable',
        },
        className: 'col',
        defaultValue:false
      },
      {
        key: 'entrada_contador_pulsos_configurable_desde',
        type: 'input',
        templateOptions: {
          placeholder:'Desde',
        },
        className: 'col',
      },
      {
        key: 'entrada_contador_pulsos_configurable_hasta',
        type: 'input',
        templateOptions: {
          placeholder:'Hasta',
        },
        className: 'col',
      },
      {
        key: 'entrada_contador_pulsos_configurable_pasos',
        type: 'input',
        templateOptions: {
          placeholder:'Pasos',
        },
        className: 'col',
      },

  ],
  },
  {     
     template: '<br><br><h5>Durante la medida</h5><br>',
 
}, 
{
  key: 'entrada_contador_medida_a',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">a)Intensidad en un contacto cerrado(=<1 Ohm)</h6>',
      className: 'col',
      },
    {
      key: 'entrada_contador_medida_intensidad_fijo',
      type: 'checkbox',
      templateOptions: {
        label: 'Fijo',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_intensidad_fijo_input',
      type: 'input',
      templateOptions: {
      },
      className: 'col',
    },
   {
  template: '<h6 class="col"></h6>',
  className: 'col',
  },
  {
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
],
},
{
  key: 'entrada_contador_medida_a_2',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col"></h6>',
      className: 'col',
        },
    {
      key: 'entrada_contador_medida_intensidad_configurable',
      type: 'checkbox',
      templateOptions: {
        label: 'Configurable',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_intensidad_configurable_desde',
      type: 'input',
      templateOptions: {
        placeholder:'Desde',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_intensidad_configurable_hasta',
      type: 'input',
      templateOptions: {
        placeholder:'Hasta',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_intensidad_configurable_pasos',
      type: 'input',
      templateOptions: {
        placeholder:'Pasos',
      },
      className: 'col',
    },
],
},
{
  key: 'entrada_contador_medida_b',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">b)Tensión de	circuito abierto (>=1M Ohm)</h6>',
      className: 'col',
      },
    {
      key: 'entrada_contador_medida_tension_fijo',
      type: 'checkbox',
      templateOptions: {
        label: 'Fijo',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_tension_fijo_input',
      type: 'input',
      templateOptions: {
      },
      className: 'col',
    },
   {
  template: '<h6 class="col"></h6>',
  className: 'col',
  },
  {
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
],
},
{
  key: 'entrada_contador_medida_b_2',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col"></h6>',
      className: 'col',
        },
    {
      key: 'entrada_contador_medida_tension_configurable',
      type: 'checkbox',
      templateOptions: {
        label: 'Configurable',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_tension_configurable_desde',
      type: 'input',
      templateOptions: {
        placeholder:'Desde',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_tension_configurable_hasta',
      type: 'input',
      templateOptions: {
        placeholder:'Hasta',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_tension_configurable_pasos',
      type: 'input',
      templateOptions: {
        placeholder:'Pasos',
      },
      className: 'col',
    },
],
},
{
  template: '<h6 class="col">c)Se considera "contacto abierto":</h6><br>',
  className: 'col',
    },
{
  key: 'entrada_contador_medida_c',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'entrada_contador_medida_b_tension_mayor',
      type: 'checkbox',
      templateOptions: {
        label: 'Si la tensión es mayor que un valor',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_c_tension_fijo',
      type: 'checkbox',
      templateOptions: {
        label: 'Fijo',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_c_tension_fijo_input',
      type: 'input',
      templateOptions: {
      },
      className: 'col',
    },
   {
  template: '<h6 class="col"></h6>',
  className: 'col',
  },
  {
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
],
},
{
  key: 'entrada_contador_medida_c_2',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col"></h6>',
      className: 'col',
      },
    {
      key: 'entrada_contador_medida_c_2_tension_configurable',
      type: 'checkbox',
      templateOptions: {
        label: 'Configurable',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_c_2_tension_configurable_desde',
      type: 'input',
      templateOptions: {
        placeholder:'Desde',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_c_2_tension_configurable_hasta',
      type: 'input',
      templateOptions: {
        placeholder:'Hasta',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_c_2_tension_configurable_pasos',
      type: 'input',
      templateOptions: {
        placeholder:'Pasos',
      },
      className: 'col',
    },
],
},

{
  key: 'entrada_contador_medida_c_3',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'entrada_contador_medida_tension_configurable',
      type: 'checkbox',
      templateOptions: {
        label: 'Si la tensión es menor que un valor',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_c_3_tension_fijo',
      type: 'checkbox',
      templateOptions: {
        label: 'Fijo',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_c_3_tension_fijo_input',
      type: 'input',
      templateOptions: {
      },
      className: 'col',
    },
   {
  template: '<h6 class="col"></h6>',
  className: 'col',
  },
  {
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
],
},
{
  key: 'entrada_contador_medida_c_4',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col"></h6>',
      className: 'col',
      },
    {
      key: 'entrada_contador_medida_c_4_tension_configurable',
      type: 'checkbox',
      templateOptions: {
        label: 'Configurable',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_c_4_tension_configurable_desde',
      type: 'input',
      templateOptions: {
        placeholder:'Desde',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_c_4_tension_configurable_hasta',
      type: 'input',
      templateOptions: {
        placeholder:'Hasta',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_c_4_tension_configurable_pasos',
      type: 'input',
      templateOptions: {
        placeholder:'Pasos',
      },
      className: 'col',
    },
],
},
{
  template: '<h6 class="col">d)Se considera "contacto cerrado":</h6><br>',
  className: 'col',
    },
{
  key: 'entrada_contador_medida_d',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'entrada_contador_medida_d_tension_menor',
      type: 'checkbox',
      templateOptions: {
        label: 'Si la tensión es menor que un valor',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_d_tension_fijo',
      type: 'checkbox',
      templateOptions: {
        label: 'Fijo',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_d_tension_fijo_input',
      type: 'input',
      templateOptions: {
      },
      className: 'col',
    },
   {
  template: '<h6 class="col"></h6>',
  className: 'col',
  },
  {
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
],
},
{
  key: 'entrada_contador_medida_d_2',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col"></h6>',
      className: 'col',
      },
    {
      key: 'entrada_contador_medida_d_2_tension_configurable',
      type: 'checkbox',
      templateOptions: {
        label: 'Configurable',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_d_2_tension_configurable_desde',
      type: 'input',
      templateOptions: {
        placeholder:'Desde',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_d_2_tension_configurable_hasta',
      type: 'input',
      templateOptions: {
        placeholder:'Hasta',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_d_2_tension_configurable_pasos',
      type: 'input',
      templateOptions: {
        placeholder:'Pasos',
      },
      className: 'col',
    },
],
},

{
  key: 'entrada_contador_medida_d_3',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      key: 'entrada_contador_medida_d_3_tension_fijo_menor',
      type: 'checkbox',
      templateOptions: {
        label: 'Si la tensión es mayor que un valor',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_d_3_tension_fijo',
      type: 'checkbox',
      templateOptions: {
        label: 'Fijo',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_d_3_tension_fijo_input',
      type: 'input',
      templateOptions: {
      },
      className: 'col',
    },
   {
  template: '<h6 class="col"></h6>',
  className: 'col',
  },
  {
  template: '<h6 class="col"></h6>',
  className: 'col',
}, 
],
},
{
  key: 'entrada_contador_medida_d_4',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col"></h6>',
      className: 'col',
      },
    {
      key: 'entrada_contador_medida_d_4_tension_configurable',
      type: 'checkbox',
      templateOptions: {
        label: 'Configurable',
      },
      className: 'col',
      defaultValue:false
    },
    {
      key: 'entrada_contador_medida_d_4_tension_configurable_desde',
      type: 'input',
      templateOptions: {
        placeholder:'Desde',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_d_4_tension_configurable_hasta',
      type: 'input',
      templateOptions: {
        placeholder:'Hasta',
      },
      className: 'col',
    },
    {
      key: 'entrada_contador_medida_d_4_tension_configurable_pasos',
      type: 'input',
      templateOptions: {
        placeholder:'Pasos',
      },
      className: 'col',
    },
],
},
{
  template: '<br>',
  },

{
  key: 'entrada_contador_medida_e',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">¿Inhibe el muestreo durante un pulso de válvula?</h6>',
      className: 'col',
      },
    {
      key: 'entrada_contador_medida_e_check',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
],
},
{
  key: 'entrada_contador_medida_f',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">¿Puede cortocircuitarse con un cable de EV?</h6>',
      className: 'col',
      },
    {
      key: 'entrada_contador_medida_f_check',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
],
},
{
  key: 'entrada_contador_medida_g',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6 class="col">¿Se pueden configurar dos entradas para un contador bidireccional?</h6>',
      className: 'col',
      },
    {
      key: 'entrada_contador_medida_g_check',
      type: 'checkbox',
      templateOptions: {
        label: 'Si',
      },
      className: 'col',
      defaultValue:false
    },
],
},
{
  key: 'entrada_contador_medida_h',
  fieldGroupClassName: 'row',
  fieldGroup: [
    {
      template: '<h6>Tipo de contador bidireccional:</h6>',
      className: 'col',
      },
      {
        key: 'entrada_contador_medida_g_check_ab',
        type: 'checkbox',
        templateOptions: {
          label: 'A/B (cuadratura)',
        },
        className: 'col',
        defaultValue:false
      },
      {
        key: 'entrada_contador_medida_g_check_ud',
        type: 'checkbox',
        templateOptions: {
          label: 'U/D (arriba/abajo)',
        },
        className: 'col',
        defaultValue:false
      },
],
}, 
  ]
  form7 = new FormGroup({});
model7 = {};
fields7: FormlyFieldConfig[] = [
  {
    template: '<h3>Entrada analógica</h3><br><br>',
    className: 'col',
    },
    {
      key: 'entrada_analogica_hilos',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Conexión</h6>',
          className: 'col',
          },
   
          {
            key: 'entrada_analogica_conexion_hilos_check',
            type: 'checkbox',
            templateOptions: {
              label: '2 hilos',
            },
            className: 'col',
            defaultValue:false
          },
          {
            template: '<h6></h6>',
            className: 'col',
            },
            {
              template: '<h6></h6>',
              className: 'col',
              },
        
    ],
    }, 
    {
      key: 'entrada_analogica_tipo',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Tipo</h6>',
          className: 'col',
          },
   
          {
            key: 'entrada_analogica_conexion_tipo_check',
            type: 'checkbox',
            templateOptions: {
              label: '4-20mA',
            },
            className: 'col',
            defaultValue:false
          },
          {
            template: '<h6></h6>',
            className: 'col',
            },
            {
              template: '<h6></h6>',
              className: 'col',
              },
        
    ],
    }, 
    {
      key: 'entrada_analogica_tipo',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6></h6>',
          className: 'col',
          },
          {
            key: 'entrada_analogica_conexion_tipo_otras',
            type: 'checkbox',
            templateOptions: {
              label: 'otras:',
            },
            className: 'col',
            defaultValue:false
          },
   
          {
            key: 'entrada_analogica_conexion_tipo_otras_input',
            type: 'input',
            templateOptions: {
              placeholder:'Especificar'
            },
            className: 'col',
          },
            {
              template: '<h6></h6>',
              className: 'col',
              },
        
    ],
    }, 
    {
      template: '<br>',
      className: 'col',
      },
    {
      key: 'entrada_analogica_resolucion',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Resolución</h6>',
          className: 'col',
          },
          {
            key: 'entrada_analogica_resolucion_valor',
            type: 'input',
            templateOptions: {
              placeholder: 'Valor',
            },
            className: 'col',
          },
   
          {
            key: 'entrada_analogica_resolucion_bits',
            type: 'checkbox',
            templateOptions: {
              label:'Bits'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'entrada_analogica_resolucion_digitos',
            type: 'checkbox',
            templateOptions: {
              label:'Digitos'
            },
            className: 'col',
            defaultValue:false
          },
        
    ],
    }, 
    {
      key: 'entrada_analogica_error',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Error total</h6>',
          className: 'col',
          },
          {
            key: 'entrada_analogica_error_input',
            type: 'input',
            templateOptions: {
              placeholder: '%',
            },
            className: 'col',
          },
          {
            template: '<h6></h6>',
            className: 'col',
            },
            {
              template: '<h6></h6>',
              className: 'col',
              },
        
    ],
    }, 
    {
      template: '<br><br>',
      },
    {
      key: 'entrada_analogica_2_hilos',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Conexión</h6>',
          className: 'col',
          },
   
          {
            key: 'entrada_analogica_conexion_2_hilos_check',
            type: 'checkbox',
            templateOptions: {
              label: '2 hilos',
            },
            className: 'col',
            defaultValue:false
          },
          {
            template: '<h6></h6>',
            className: 'col',
            },
            {
              template: '<h6></h6>',
              className: 'col',
              },
        
    ],
    }, 
    {
      key: 'entrada_analogica_tipo',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Tipo</h6>',
          className: 'col',
          },
   
          {
            key: 'entrada_analogica_conexion_2_tipo_check',
            type: 'checkbox',
            templateOptions: {
              label: '4-20mA',
            },
            className: 'col',
            defaultValue:false
          },
          {
            template: '<h6></h6>',
            className: 'col',
            },
            {
              template: '<h6></h6>',
              className: 'col',
              },
        
    ],
    }, 
    {
      key: 'entrada_analogica_2_tipo',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6></h6>',
          className: 'col',
          },
          {
            key: 'entrada_analogica_conexion_2_tipo_otras',
            type: 'checkbox',
            templateOptions: {
              label: 'otras:',
            },
            className: 'col',
            defaultValue:false
          },
   
          {
            key: 'entrada_analogica_conexion_2_tipo_otras_input',
            type: 'input',
            templateOptions: {
              placeholder:'Especificar'
            },
            className: 'col',
          },
            {
              template: '<h6></h6>',
              className: 'col',
              },
        
    ],
    }, 
    {
      template: '<br>',
      className: 'col',
      },
    {
      key: 'entrada_analogica_2_resolucion',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Resolución</h6>',
          className: 'col',
          },
          {
            key: 'entrada_analogica_2_resolucion_valor',
            type: 'input',
            templateOptions: {
              placeholder: 'Valor',
            },
            className: 'col',
          },
   
          {
            key: 'entrada_analogica_2_resolucion_bits',
            type: 'checkbox',
            templateOptions: {
              label:'Bits'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'entrada_analogica_2_resolucion_digitos',
            type: 'checkbox',
            templateOptions: {
              label:'Digitos'
            },
            className: 'col',
            defaultValue:false
          },
        
    ],
    }, 
    {
      key: 'entrada_analogica_2_error',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Error total</h6>',
          className: 'col',
          },
          {
            key: 'entrada_analogica_2_error_input',
            type: 'input',
            templateOptions: {
              placeholder: '%',
            },
            className: 'col',
          },
          {
            template: '<h6></h6>',
            className: 'col',
            },
            {
              template: '<h6></h6>',
              className: 'col',
              },    
    ],
    }, 
    {
      template: '<br>',
      },

    {
      key: 'entrada_analogica_sensor_alimentacion',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Alimentación del sensor</h6>',
          className: 'col',
          },
          {
            template: '<h6></h6>',
            className: 'col',
            },
          {
            key: 'entrada_analogica_sensor_no',
            type: 'checkbox',
            templateOptions: {
              label:'No'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'entrada_analogica_sensor_siempre',
            type: 'checkbox',
            templateOptions: {
              label:'Siempre'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'entrada_analogica_sensor_antes',
            type: 'checkbox',
            templateOptions: {
              label:'Antes de la muestra'
            },
            className: 'col',
            defaultValue:false
          },
      
       
    ],
    }, 

    {
      key: 'entrada_analogica_sensor_tension',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Tensión de alimentación del sensor</h6>',
          className: 'col',
          },
          {
            key: 'entrada_analogica_sensor_tension_fija',
            type: 'checkbox',
            templateOptions: {
              label:'Fija'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'entrada_analogica_sensor_tension_fija_input',
            type: 'input',
            templateOptions: {
            },
            className: 'col',
          },
          {
            key: 'entrada_analogica_sensor_tension_fija_sw',
            type: 'checkbox',
            templateOptions: {
              label:'Sw'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'entrada_analogica_sensor_tension_fija_hw',
            type: 'checkbox',
            templateOptions: {
              label:'Hw'
            },
            className: 'col',
            defaultValue:false
          },

    ],
    }, 
    {
      key: 'entrada_analogica_sensor_tension_2',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col"></h6>',
          className: 'col',
            },
        {
          key: 'entrada_analogica_sensor_tension_configurable',
          type: 'checkbox',
          templateOptions: {
            label: 'Configurable',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_analogica_sensor_tension_configurable_desde',
          type: 'input',
          templateOptions: {
            placeholder:'Desde',
          },
          className: 'col',
        },
        {
          key: 'entrada_analogica_sensor_tension_configurable_hasta',
          type: 'input',
          templateOptions: {
            placeholder:'Hasta',
          },
          className: 'col',
        },
        {
          key: 'entrada_analogica_sensor_tension_configurable_pasos',
          type: 'input',
          templateOptions: {
            placeholder:'Pasos',
          },
          className: 'col',
        },
 
    ],
    },
    {
      template: '<br>'
        },

    {
      key: 'entrada_analogica_sensor_tiempo',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6>Tiempo antes de tomar la muestra</h6>',
          className: 'col',
          },
          {
            key: 'entrada_analogica_sensor_tiempo_fijo',
            type: 'checkbox',
            templateOptions: {
              label:'Fijo'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'entrada_analogica_sensor_tiempo_fijo_input',
            type: 'input',
            templateOptions: {
            },
            className: 'col',
          },
          {
            key: 'entrada_analogica_sensor_tiempo_fijo_sw',
            type: 'checkbox',
            templateOptions: {
              label:'Sw'
            },
            className: 'col',
            defaultValue:false
          },
          {
            key: 'entrada_analogica_sensor_tiempo_fijo_hw',
            type: 'checkbox',
            templateOptions: {
              label:'Hw'
            },
            className: 'col',
            defaultValue:false
          },

    ],
    }, 
    {
      key: 'entrada_analogica_sensor_tiempo_2',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col"></h6>',
          className: 'col',
            },
        {
          key: 'entrada_analogica_sensor_tiempo_2_configurable',
          type: 'checkbox',
          templateOptions: {
            label: 'Configurable',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_analogica_sensor_tiempo_2_configurable_desde',
          type: 'input',
          templateOptions: {
            placeholder:'Desde',
          },
          className: 'col',
        },
        {
          key: 'entrada_analogica_sensor_tiempo_2_configurable_hasta',
          type: 'input',
          templateOptions: {
            placeholder:'Hasta',
          },
          className: 'col',
        },
        {
          key: 'entrada_analogica_sensor_tiempo_2_configurable_pasos',
          type: 'input',
          templateOptions: {
            placeholder:'Pasos',
          },
          className: 'col',
        },
 
    ],
    },

    {
      key: 'entrada_analogica_sensor_cortocircuito',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col">Permite cortocircuito en la salida	de alimentación del sensor</h6>',
          className: 'col',
            },
        {
          key: 'entrada_analogica_sensor_cortocircuito_no',
          type: 'checkbox',
          templateOptions: {
            label: 'No',
          },
          className: 'col',
          defaultValue:false
        },
        {
          template: '<h6 class="col"></h6>',
          className: 'col',
            },
        {
          template: '<h6 class="col"></h6>',
          className: 'col',
            },
            {
          template: '<h6 class="col"></h6>',
          className: 'col',
            },
 
    ],
    },
    {
      key: 'entrada_analogica_sensor_cortocircuito_2',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col"></h6>',
          className: 'col',
            },
        {
          key: 'entrada_analogica_sensor_cortocircuito_si',
          type: 'checkbox',
          templateOptions: {
            label: 'Si',
          },
          className: 'col',
          defaultValue:false
        },
        {
          template: '<h6 class="col">¿Genera alarma?</h6>',
          className: 'col',
            },
            {
              key: 'entrada_analogica_sensor_cortocircuito_si_input',
              type: 'input',
              templateOptions: {
                placeholder: 'Especificar',
              },
              className: 'col',
            },
            {
          template: '<h6 class="col"></h6>',
          className: 'col',
            },
 
    ],
    },
    {
      template: '<br>',
      className: 'col',
        },
    {
      key: 'entrada_analogica_sensor_cortocircuito_3',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'entrada_analogica_sensor_cortocircuito_permitir',
          type: 'checkbox',
          templateOptions: {
            label: 'Permite cortocircuito',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_analogica_sensor_cortocircuito_alarma',
          type: 'checkbox',
          templateOptions: {
            label: 'Genera alarma',
          },
          className: 'col',
          defaultValue:false
        },
        {
          key: 'entrada_analogica_sensor_cortocircuito_nivel',
          type: 'checkbox',
          templateOptions: {
            label: 'Nivel configurable',
          },
          className: 'col',
          defaultValue:false
        },
            {
              key: 'entrada_analogica_sensor_cortocircuito_min',
              type: 'input',
              templateOptions: {
                placeholder: 'Mínimo',
              },
              className: 'col',
            },
            {
              key: 'entrada_analogica_sensor_cortocircuito_max',
              type: 'input',
              templateOptions: {
                placeholder: 'Máximo',
              },
              className: 'col',
            },
            {
              key: 'entrada_analogica_sensor_cortocircuito_pasos',
              type: 'input',
              templateOptions: {
                placeholder: 'Pasos',
              },
              className: 'col',
            },
    ],
    },
    {
      template: '<br><h6 class="col">Se puede programar  para tomar muestras periódicamente</h6><br>',
  
        },
        {
          key: 'entrada_analogica_sensor_programar',
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              key: 'entrada_analogica_sensor_programar_periodicamente_no',
              type: 'checkbox',
              templateOptions: {
                label: 'No',
              },
              className: 'col',
              defaultValue:false
            },
            {
              template: '<h6 class="col"></h6>',
              className: 'col',
                },
                {
                  template: '<h6 class="col"></h6>',
                  className: 'col',
                    },
                    {
                      template: '<h6 class="col"></h6>',
                      className: 'col',
                        },
                        {
                          template: '<h6 class="col"></h6>',
                          className: 'col',
                            },
     
        ],
        },
        {
          key: 'entrada_analogica_sensor_programar_2',
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              key: 'entrada_analogica_sensor_programar_periodicamente_si',
              type: 'checkbox',
              templateOptions: {
                label: 'Si',
              },
              className: 'col',
              defaultValue:false
            },
            {
              template: '<h6 class="col">¿Cada cuanto tiempo?</h6>',
              className: 'col',
                },
                {
                  key: 'entrada_analogica_sensor_cortocircuito_programar_desde',
                  type: 'input',
                  templateOptions: {
                    placeholder: 'Desde',
                  },
                  className: 'col',
                },
                {
                  key: 'entrada_analogica_sensor_cortocircuito_programar_hasta',
                  type: 'input',
                  templateOptions: {
                    placeholder: 'Hasta',
                  },
                  className: 'col',
                },
                {
                  key: 'entrada_analogica_sensor_cortocircuito_programar_pasos',
                  type: 'input',
                  templateOptions: {
                    placeholder: 'Pasos',
                  },
                  className: 'col',
                },
     
        ],
        },
        {
          template: '<br>',
            },

        {
          key: 'entrada_analogica_sensor_programar_datos',
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              template: '<h6 class="col"></h6>',
              className: 'col',
                },
                {
                  template: '<h6 class="col">¿Almacena datos?</h6>',
                  className: 'col',
                    },
            {
              key: 'entrada_analogica_sensor_programar_datos_no',
              type: 'checkbox',
              templateOptions: {
                label: 'No',
              },
              className: 'col',
              defaultValue:false
            },
            {
              template: '<h6 class="col"></h6>',
              className: 'col',
                },
                {
                  template: '<h6 class="col"></h6>',
                  className: 'col',
                    },
        ],
        },
        {
          key: 'entrada_analogica_sensor_programar_datos_2',
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              template: '<h6 class="col"></h6>',
              className: 'col',
                },
                {
                  template: '<h6 class="col"></h6>',
                  className: 'col',
                    },
            {
              key: 'entrada_analogica_sensor_programar_datos_si',
              type: 'checkbox',
              templateOptions: {
                label: 'Si',
              },
              className: 'col',
              defaultValue:false
            },
            {
              template: '<h6 class="col">Número de muestras</h6>',
              className: 'col',
                },
                {
                  key: 'entrada_analogica_sensor_programar_datos_si_input',
                  type: 'input',
                  templateOptions: {
                    placeholder: 'Nº de muestras',
                  },
                  className: 'col',
                },
        ],
        },
        {
          key: 'entrada_analogica_sensor_programar_datos_3',
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              template: '<h6 class="col"></h6>',
              className: 'col',
                },
                {
                  template: '<h6 class="col"></h6>',
                  className: 'col',
                    },
                    {
                      template: '<h6 class="col"></h6>',
                      className: 'col',
                        },
            {
              template: '<h6 class="col">¿Con fecha?</h6>',
              className: 'col',
                },
                {
                  key: 'entrada_analogica_sensor_programar_datos_fecha',
                  type: 'checkbox',
                  templateOptions: {
                    label: 'Si',
                  },
                  className: 'col',
                  defaultValue:false
                },
        ],
        },
        {
          template: '<br>',
            },

        {
          key: 'entrada_analogica_sensor_programar_datos_3',
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              template: '<h6 class="col"></h6>',
              className: 'col',
                },
                {
                  template: '<h6 class="col">¿Genera alarmas?</h6>',
                  className: 'col',
                    },
                    {
                      key: 'entrada_analogica_sensor_programar_alarmas_si',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'Si',
                      },
                      className: 'col',
                      defaultValue:false
                    },
            {
              template: '<h6 class="col">de tipo:</h6>',
              className: 'col',
                },
                {
                  key: 'entrada_analogica_sensor_programar_alarmas_si_input',
                  type: 'input',
                  templateOptions: {
                    placeholder: 'tipo',
                  },
                  className: 'col',
                },
        ],
        },
        {
          template: '<h6 class="col">Observaciones</h6><br>',
          className: 'col',
            },
        {
          key: 'entrada_analogica_observaciones',
          type: 'textarea',
          templateOptions: {
              placeholder:"Especificar observaciones"
          },
        },
  ]
  form8 = new FormGroup({});
  model8 = {};
  fields8: FormlyFieldConfig[] = [
    {
      template: '<br>',
      className: 'col',
        },
    {
      key: 'tipo_comunicaciones',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h3 class="col">Tipo de comunicaciones</h3>',
          className: 'col',
            },
            {
              key: 'tipo_comunicaciones_input',
              type: 'input',
              templateOptions: {
                placeholder: 'Tipo',
              },
              className: 'col',
            },
    ],
    },
  
    {
      key: 'tipo_comunicaciones_parte',
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          template: '<h6 class="col">Explicar en qué parte(s) del sistema se aplica</h6>',
          className: 'col',
            },
            {
              key: 'tipo_comunicaciones_parte_input',
              type: 'input',
              templateOptions: {
                placeholder: 'Especificar aquí',
              },
              className: 'col',
            },
    ],
    },
    {
      template: '<br><p class="col"><b>Nota:</b> Si en un mismo sistema hay más de un tipo de comunicaciones (por ejemplo GPRS entre el centro de control y concentrador, y cable del concentrador a las remotas), rellenar tantas hojas de tipo de comunicaciones como sean necesarias para describir cada una de ellas.</p><br>',
      className: 'col',
        },
        {
          template: '<h6>Medio físico</h6><br>',
          className: 'col',
            },
            {
              key: 'tipo_comunicaciones_fisico',
              fieldGroupClassName: 'row',
              fieldGroup: [
                {
                  key: 'tipo_comunicaciones_fisico_cable',
                  type: 'checkbox',
                  templateOptions: {
                    label: 'Cable',
                  },
                  className: 'col',
                  defaultValue:false
                },
                    {
                      template: '<h6 class="col">Con protocolo</h6>',
                      className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_fisico_propietario',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Propietario',
                          },
                          className: 'col',
                          defaultValue:false
                        },
             
                    {
                      key: 'tipo_comunicaciones_fisico_propietario_input',
                      type: 'input',
                      templateOptions: {
                        placeholder: 'Propietario',
                      },
                      className: 'col',
                    },
            ],
            },
            {
              key: 'tipo_comunicaciones_fisico_2',
              fieldGroupClassName: 'row',
              fieldGroup: [
                {
                  template: '<h6 class="col"></h6>',
                  className: 'col',
                    },
                    {
                      template: '<h6 class="col"></h6>',
                      className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_fisico_estandar',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Estándar',
                          },
                          className: 'col',
                          defaultValue:false
                        },
             
                    {
                      key: 'tipo_comunicaciones_fisico_estandar_input',
                      type: 'input',
                      templateOptions: {
                        placeholder: 'Estándar',
                      },
                      className: 'col',
                    },
            ],
            },
            {
              template: '<br>',
                },
                {
                  key: 'tipo_comunicaciones_numero',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col">Número máximo de unidades que pueden llegar a conectar en un mismo segmento de cable : </h6>',
                      className: 'col',
                        },  
                        {
                          key: 'tipo_comunicaciones_numero_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Número',
                          },
                          className: 'col',
                        },
                ],
                },
                {
                  key: 'tipo_comunicaciones_longitud',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col">Longitud máxima de un segmento de cable </h6>',
                      className: 'col',
                        },  
                        {
                          key: 'tipo_comunicaciones_longitud_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Longitud',
                          },
                          className: 'col',
                        },
                ],
                },
                {
                  key: 'tipo_comunicaciones_aislado',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col">¿El cable de comunicaciones se encuentra aislado galvánicamente del cable de solenoide y de contador, etc. (elementos hidráulicos)?</h6>',
                      className: 'col',
                        },  
                        {
                          key: 'tipo_comunicaciones_aislado_checkbox',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Si',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_aislado_input',
                          type: 'textarea',
                          templateOptions: {
                            placeholder: 'Especificar',
                          },
                          className: 'col',
                        },
                        
                ],
                },
                {
                  template: '<br>',
                  className: 'col',
                    },  

                {
                  key: 'tipo_comunicaciones_elementos',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col">¿Qué elementos de obra se necesitan en cada Terminal remota (Arquetas, picas de tierra, etc)?</h6>',
                      className: 'col',
                        },  
              
                        {
                          key: 'tipo_comunicaciones_elementos_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Elementos',
                          },
                          className: 'col',
                        },
                        
                ],
                },

                {
                  template: '<br><br>',
                  className: 'col',
                    },  

                {
                  key: 'tipo_comunicaciones_radio',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      key: 'tipo_comunicaciones_radio_check',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'Radio',
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      template: '<h6 class="col">Con operador de telefonía</h6>',
                      className: 'col',
                        },  
                        {
                          template: '<h6 class="col"></h6>',
                          className: 'col',
                            },  
                            {
                              template: '<h6 class="col"></h6>',
                              className: 'col',
                                },  
                    
                        
                ],
                },
                
                {
                  key: 'tipo_comunicaciones_radio_operador',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col"></h6>',
                      className: 'col',
                        },
                    {
                      key: 'tipo_comunicaciones_radio_check_gsm',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'GSM',
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      key: 'tipo_comunicaciones_radio_check_gprs',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'GPRS',
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      key: 'tipo_comunicaciones_radio_check_umts',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'UMTS',
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      key: 'tipo_comunicaciones_radio_check_wimax',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'WIMAX',
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      key: 'tipo_comunicaciones_radio_check_otro',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'Otro',
                      },
                      className: 'col',
                      defaultValue:false
                    },
                    {
                      key: 'tipo_comunicaciones_radio_check_otro_input',
                      type: 'input',
                      templateOptions: {
                        placeholder: 'Otro',
                      },
                      className: 'col',
                    },   
                ],
                },
                {
                  key: 'tipo_comunicaciones_radio_sin_operador',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col"></h6>',
                      className: 'col',
                        },
                        {
                          template: '<h6 class="col">Sin  operador de telefonía y con protocolo:</h6>',
                          className: 'col',
                            },
                            {
                              template: '<h6 class="col"></h6>',
                              className: 'col',
                                },
                                {
                                  template: '<h6 class="col"></h6>',
                                  className: 'col',
                                    },
                ],
                },
                {
                  template: '<br>',
                  className: 'col',
                    },
                {
                  key: 'tipo_comunicaciones_radio_sin_operador_propietario',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col"></h6>',
                      className: 'col',
                        },
                        {
                          template: '<h6 class="col">Propietario</h6>',
                          className: 'col',
                            },
                        {
                          key: 'tipo_comunicaciones_radio_sin_operador_propietario_check_uhf433',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'UHF433',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_sin_operador_propietario_check_uhf868',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'UHF868',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_sin_operador_propietario_check_otro',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Otro',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_sin_operador_propietario_check_otro_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Otro',
                          },
                          className: 'col',
                        },  
                ],
                },
                {
                  key: 'tipo_comunicaciones_radio_sin_operador_estandar',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col"></h6>',
                      className: 'col',
                        },
                        {
                          template: '<h6 class="col">Estándar</h6>',
                          className: 'col',
                            },
                        {
                          key: 'tipo_comunicaciones_radio_sin_operador_estandar_check_zigbee',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'ZigBee',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_sin_operador_estandar_check_wifi',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'WiFi',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_sin_operador_estandar_check_otro',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Otro',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_sin_operador_estandar_check_otro_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Otro',
                          },
                          className: 'col',
                        },  
                ],
                },
                {
                  template: '<br>',
                    },

                {
                  key: 'tipo_comunicaciones_radio_frecuencia',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col">Frecuencia</h6>',
                      className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_radio_frecuencia_fija',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Fija',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_frecuencia_fija_input',
                          type: 'input',
                          templateOptions: {
                          },
                          className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_radio_frecuencia_configurable',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Configurable',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_frecuencia_fija_desde',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Desde',
                          },
                          className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_radio_frecuencia_hasta',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Hasta',
                          },
                          className: 'col',
                        },  
                        {
                          key: 'tipo_comunicaciones_radio_frecuencia_pasos',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Pasos',
                          },
                          className: 'col',
                        },  
                ],
                },
                {
                  key: 'tipo_comunicaciones_radio_potencia',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col">Potencia TX</h6>',
                      className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_radio_potencia_fija',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Fija',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_potencia_fija_input',
                          type: 'input',
                          templateOptions: {
                          },
                          className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_radio_potencia_configurable',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Configurable',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_potencia_fija_desde',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Desde',
                          },
                          className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_radio_potencia_hasta',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Hasta',
                          },
                          className: 'col',
                        },  
                        {
                          key: 'tipo_comunicaciones_radio_potencia_pasos',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Pasos',
                          },
                          className: 'col',
                        },  
                ],
                },
                {
                  key: 'tipo_comunicaciones_radio_velocidad',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6 class="col">Velocidad</h6>',
                      className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_radio_velocidad_fija',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Fija',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_velocidad_fija_input',
                          type: 'input',
                          templateOptions: {
                          },
                          className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_radio_velocidad_configurable',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Configurable',
                          },
                          className: 'col',
                          defaultValue:false
                        },
                        {
                          key: 'tipo_comunicaciones_radio_velocidad_fija_desde',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Desde',
                          },
                          className: 'col',
                        },
                        {
                          key: 'tipo_comunicaciones_radio_velocidad_hasta',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Hasta',
                          },
                          className: 'col',
                        },  
                        {
                          key: 'tipo_comunicaciones_radio_velocidad_pasos',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Pasos',
                          },
                          className: 'col',
                        },  
                ],
                },
                {
                  template: '<br><h6 class="col">Especificaciones recomendadas, para el planteamiento de una instalación vía radio:</h6><br>',
                    },

                    {
                      key: 'tipo_comunicaciones_radio_distancia',
                      fieldGroupClassName: 'row',
                      fieldGroup: [
                        {
                          template: '<h6 class="col">Distancia máxima recomendada para un enlace radio</h6>',
                          className: 'col',
                            },
                            {
                              key: 'tipo_comunicaciones_radio_distancia_input',
                              type: 'input',
                              templateOptions: {
                                placeholder: 'Metros',
                              },
                              className: 'col',
                            },  
                    ],
                    },
                    {
                      template: '<br><h6 class="col">Si una remota puede actuar como encaminador para otras (multihopping):</h6><br>',
                        },
    
                        {
                          key: 'tipo_comunicaciones_radio_remota',
                          fieldGroupClassName: 'row',
                          fieldGroup: [
                            {
                              template: '<h6 class="col">Cada salto aumenta el retardo de la comunicación</h6>',
                              className: 'col',
                                },
                                {
                                  key: 'tipo_comunicaciones_radio_remota_input',
                                  type: 'input',
                                  templateOptions: {
                                    placeholder: 'Segundos',
                                  },
                                  className: 'col',
                                },  
                        ],
                        },
                        {
                          key: 'tipo_comunicaciones_radio_remota_alimentacion',
                          fieldGroupClassName: 'row',
                          fieldGroup: [
                            {
                              template: '<h6 class="col">¿Cómo afecta la función de repetidor de comunicaciones al dimensionamiento de la alimentación de las remotas?</h6>',
                              className: 'col',
                                },
                                {
                                  key: 'tipo_comunicaciones_radio_remota_alimentacion_input',
                                  type: 'input',
                                  templateOptions: {
                                    placeholder: 'Especificar',
                                  },
                                  className: 'col',
                                },  
                        ],
                        },
                        {
                          key: 'tipo_comunicaciones_radio_remota_alimentacion',
                          fieldGroupClassName: 'row',
                          fieldGroup: [
                            {
                              template: '<h6 class="col">¿Se aconseja instalar algún elemento de protección contra campos eléctricos intensos de baja frecuencia en las proximidades de la antena?</h6>',
                              className: 'col',
                                },
                                {
                                  key: 'tipo_comunicaciones_radio_proteccion_checkbox',
                                  type: 'checkbox',
                                  templateOptions: {
                                    label: 'Si',
                                  },
                                  className: 'col',
                                  defaultValue:false
                                }, 
                                {
                                  key: 'tipo_comunicaciones_radio_proteccion_input',
                                  type: 'textarea',
                                  templateOptions: {
                                    placeholder: 'Detallar',
                                  },
                                  className: 'col',
                                },  
                        ],
                        },
  ]
  form9 = new FormGroup({});
  model9 = {};
  fields9: FormlyFieldConfig[] = [

    {
      template: '<br>',
      className: 'col',
        },
        {
          key: 'arquitectura_red',
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              template: '<h3 class="col">Arquitectura de red</h3>',
              className: 'col',
                },
                {
                  key: 'arquitectura_red_poll',
                  type: 'checkbox',
                  templateOptions: {
                    label: 'Master-Slave (Poll)',
                  },
                  className: 'col',
                  defaultValue:false
                }, 
                {
                  key: 'arquitectura_red_eventos',
                  type: 'checkbox',
                  templateOptions: {
                    label: 'Peer2Peer (Eventos)',
                  },
                  className: 'col',
                  defaultValue:false
                }, 
              
        ],
        },
        {
          template: '<br><h5>Topología de red</h5><br>',
          className: 'col',
            },
            {
              key: 'arquitectura_red_topologia',
              fieldGroupClassName: 'row',
              fieldGroup: [
           
                    {
                      key: 'arquitectura_red_topologia_bus',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'Bus',
                      },
                      className: 'col',
                      defaultValue:false
                    }, 
                    {
                      key: 'arquitectura_red_topologia_estrella',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'Estrella',
                      },
                      className: 'col',
                      defaultValue:false
                    }, 
                    {
                      key: 'arquitectura_red_topologia_arbol',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'Árbol',
                      },
                      className: 'col',
                      defaultValue:false
                    }, 
                    {
                      key: 'arquitectura_red_topologia_malla',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'Malla',
                      },
                      className: 'col',
                      defaultValue:false
                    }, 
                    {
                      key: 'arquitectura_red_topologia_mixta',
                      type: 'checkbox',
                      templateOptions: {
                        label: 'Mixta',
                      },
                      className: 'col',
                      defaultValue:false
                    }, 
                    {
                      key: 'arquitectura_red_topologia_mixta_input',
                      type: 'input',
                      templateOptions: {
                        placeholder:"Especificar"
                      },
                      className: 'col',
                    }, 
            ],
            },
            {
              template: '<br>',
              className: 'col',
                },

                {
                  key: 'arquitectura_red_topologia_calidad',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6>¿Mide la calidad del enlace?</h6>',
                      className: 'col',
                        },
               
                        {
                          key: 'arquitectura_red_topologia_calidad_si',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Si',
                          },
                          className: 'col',
                          defaultValue:false
                        }, 
                        {
                          key: 'arquitectura_red_topologia_calidad_si_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Especificar',
                          },
                          className: 'col',
                        },
                ],
                },
                
                {
                  key: 'arquitectura_red_topologia_rutas',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6>¿Tiene varias rutas posibles?</h6>',
                      className: 'col',
                        },
                        {
                          key: 'arquitectura_red_topologia_rutas_si',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Si',
                          },
                          className: 'col',
                          defaultValue:false
                        }, 
                        {
                          key: 'arquitectura_red_topologia_rutas_si_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Especificar',
                          },
                          className: 'col',
                        },
                ],
                },
                {
                  key: 'arquitectura_red_topologia_canal',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6>¿Tiene un canal prioritario?</h6>',
                      className: 'col',
                        },
                        {
                          key: 'arquitectura_red_topologia_canal_si',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Si',
                          },
                          className: 'col',
                          defaultValue:false
                        }, 
                        {
                          key: 'arquitectura_red_topologia_canal_si_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Especificar',
                          },
                          className: 'col',
                        },
                ],
                },
                {
                  key: 'arquitectura_red_topologia_configuracion',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6>¿Tiene auto-configuración de red? (Conecta automáticamente con los elementos nuevos añadidos en campo)</h6>',
                      className: 'col',
                        },
                        {
                          key: 'arquitectura_red_topologia_configuracion_si',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Si',
                          },
                          className: 'col',
                          defaultValue:false
                        }, 
                        {
                          key: 'arquitectura_red_topologia_configuracion_si_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Especificar',
                          },
                          className: 'col',
                        },
                ],
                },
                {
                  template: '<br>',
                  className: 'col',
                    },
                {
                  key: 'arquitectura_red_topologia_encriptacion',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6>¿Soporta encriptación?</h6>',
                      className: 'col',
                        },
                        {
                          key: 'arquitectura_red_topologia_encriptacion_si',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Si',
                          },
                          className: 'col',
                          defaultValue:false
                        }, 
                        {
                          key: 'arquitectura_red_topologia_encriptacion_si_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Especificar',
                          },
                          className: 'col',
                        },
                ],
                },
                {
                  key: 'arquitectura_red_topologia_licencia',
                  fieldGroupClassName: 'row',
                  fieldGroup: [
                    {
                      template: '<h6>¿Necesita licencia administrativa?</h6>',
                      className: 'col',
                        },
                        {
                          key: 'arquitectura_red_topologia_licencia_si',
                          type: 'checkbox',
                          templateOptions: {
                            label: 'Si',
                          },
                          className: 'col',
                          defaultValue:false
                        }, 
                        {
                          key: 'arquitectura_red_topologia_licencia_si_input',
                          type: 'input',
                          templateOptions: {
                            placeholder: 'Especificar',
                          },
                          className: 'col',
                        },
                ],
                },
                {
                  template: '<h6>Observaciones</h6><br>',
                  className: 'col',
                    },
                {
                  key: 'arquitectura_red_topologia_observaciones',
                  type: 'textarea',
                  templateOptions: {
                    placeholder: 'Observaciones aquí',
                  },
                },
                {
                  template: '<br><h5>Mantenimiento y explotación</h5><br>',
                  className: 'col',
                    },
                    {
                      key: 'matenimiento_explotacion',
                      fieldGroupClassName: 'row',
                      fieldGroup: [
                        {
                          template: '<h6>¿Tiene elementos que sea necesario sustituir cada cierto tiempo ? (pilas, baterías)</h6>',
                          className: 'col',
                            },
                            {
                              key: 'matenimiento_explotacion_pilas_si',
                              type: 'checkbox',
                              templateOptions: {
                                label: 'Si',
                              },
                              className: 'col',
                              defaultValue:false
                            }, 
                            {
                              key: 'matenimiento_explotacion_pilas_no',
                              type: 'checkbox',
                              templateOptions: {
                                label: 'No',
                              },
                              className: 'col',
                              defaultValue:false
                            }, 
                            
                          
                    ],
                    },
                    {
                      template: '<br>',
                      className: 'col',
                        },
                    {
                      key: 'matenimiento_explotacion_tiempo',
                      fieldGroupClassName: 'row',
                      fieldGroup: [
                        {
                          template: '<h6>Dar una estimación del tiempo de vida de cada uno de esos elementos</h6>',
                          className: 'col',
                            },
                            {
                              key: 'matenimiento_explotacion_tiempo_input',
                              type: 'textarea',
                              templateOptions: {
                                placeholder: 'Tiempo de vida',
                              },
                              className: 'col',
                            }, 
                    ],
                    },
                    {
                      key: 'matenimiento_explotacion_plan',
                      fieldGroupClassName: 'row',
                      fieldGroup: [
                        {
                          template: '<h6>Detallar si existe algún plan de mantenimiento preventivo, correctivo, etc:</h6>',
                          className: 'col',
                            },
                            {
                              key: 'matenimiento_explotacion_plan_input',
                              type: 'textarea',
                              templateOptions: {
                                placeholder: 'Detallar plan preventivo',
                              },
                              className: 'col',
                            }, 
                    ],
                    },
                    {
                      template: '<br><h5>Costes</h5><br>',
                        },
                        {
                          template: '<h6>Se pretende estimar los costes totales que va a suponer la explotación del sistema de telecontrol a instalar.</h6><br>',
                            },
                            {
                              template: '<p><b>Costes Totales</b> = Costes fijos + Costes variables</p><br>',
                                },
                                {
                                  template: '<p><b>Costes fijos</b> (cambio de baterías, licencias de comunicaciones, mantenimiento de hardware y de software, término fijo de la factura eléctrica, etc…)</p><br>',
                                    },
                                    {
                                      key: 'costes_fijos',
                                      type: 'textarea',
                                      templateOptions: {
                                        placeholder: 'Especificar costes fijos',
                                      },
                                      className: 'col',
                                    },
                                    {
                                      template: '<p><b>Costes Variables</b> (costes de tráfico de comunicaciones, por consumos de energía, etc.)</p><br>',
                                        },
                                        {
                                          key: 'costes_variables',
                                          type: 'textarea',
                                          templateOptions: {
                                            placeholder: 'Especificar costes variables',
                                          },
                                          className: 'col',
                                        },
                                        {
                                          template: '<br><h5>Garantías</h5><br>',
                                            },
                                                {
                                                  key: 'garantias_ofrecen',
                                                  fieldGroupClassName: 'row',
                                                  fieldGroup: [
                                                    {
                                                      template: '<h6>Tiempo estimado de garantía que ofrecen de su sistema</h6><br>',
                                                      className: 'col',
                                                        },
                                                        {
                                                          key: 'garantias_ofrecen_no',
                                                          type: 'checkbox',
                                                          templateOptions: {
                                                            label: 'No ofrecen garantía',
                                                          },
                                                          className: 'col',
                                                          defaultValue:false
                                                        },     
                                                        {
                                                          key: 'garantias_ofrecen_si',
                                                          type: 'checkbox',
                                                          templateOptions: {
                                                            label: 'Si ofrecen garantía',
                                                          },
                                                          className: 'col',
                                                          defaultValue:false
                                                        }, 
                                                ],
                                                },
                                                {
                                                  key: 'garantias_ofrecen_tiempo',
                                                  fieldGroupClassName: 'row',
                                                  fieldGroup: [
                                                    {
                                                      template: '<h6>Tiempo</h6><br>',
                                                      className: 'col',
                                                        },
                                                        {
                                                          key: 'garantias_ofrecen_tiempo_menos',
                                                          type: 'checkbox',
                                                          templateOptions: {
                                                            label: '< 1 año',
                                                          },
                                                          className: 'col',
                                                          defaultValue:false
                                                        },     
                                                        {
                                                          key: 'garantias_ofrecen_tiempo_entre',
                                                          type: 'checkbox',
                                                          templateOptions: {
                                                            label: '1 año - 2 años',
                                                          },
                                                          className: 'col',
                                                          defaultValue:false
                                                        }, 
                                                        {
                                                          key: 'garantias_ofrecen_tiempo_entre',
                                                          type: 'checkbox',
                                                          templateOptions: {
                                                            label: '> 2 años',
                                                          },
                                                          className: 'col',
                                                          defaultValue:false
                                                        }, 
                                                        {
                                                          key: 'garantias_ofrecen_tiempo_cuanto',
                                                          type: 'input',
                                                          templateOptions: {
                                                            placeholder: '¿Cuánto?',
                                                          },
                                                          className: 'col',
                                                        }, 
                                                ],
                                                },
                                                {
                                                  template: '<br><h6>Qué incluye la garantía de su sistema:</h6><br>',
                                                    },
                                                    {
                                                      key: 'garantias_ofrecen_elementos_campo',
                                                      type: 'checkbox',
                                                      templateOptions: {
                                                        label: 'Sustitución de elementos defectuosos en campo',
                                                      },
                                                      className: 'col',
                                                      defaultValue:false
                                                    }, 
                                                    {
                                                      key: 'garantias_ofrecen_elementos_no_campo',
                                                      type: 'checkbox',
                                                      templateOptions: {
                                                        label: 'Sustitución de elementos defectuosos, pero no en campo',
                                                      },
                                                      className: 'col',
                                                      defaultValue:false
                                                    }, 
                                                    {
                                                      key: 'garantias_ofrecen_actualizaciones',
                                                      type: 'checkbox',
                                                      templateOptions: {
                                                        label: 'Actualizaciones del sistema',
                                                      },
                                                      className: 'col',
                                                      defaultValue:false
                                                    }, 
                                                    {
                                                      key: 'garantias_ofrecen_revisiones',
                                                      type: 'checkbox',
                                                      templateOptions: {
                                                        label: 'Revisiones periódicas del sistema',
                                                      },
                                                      className: 'col',
                                                      defaultValue:false
                                                    }, 
                                                    {
                                                      key: 'garantias_ofrecen_mantenimiento',
                                                      type: 'checkbox',
                                                      templateOptions: {
                                                        label: 'Mantenimiento integral del sistema',
                                                      },
                                                      className: 'col',
                                                      defaultValue:false
                                                    }, 
                                                    {
                                                      template: '<br><h6>Detallar la cobertura de la garantía ofertada:</h6><br>',
                                                        },
                                                        {
                                                          key: 'garantias_ofrecen_cobertura',
                                                          type: 'textarea',
                                                          templateOptions: {
                                                            placeholder: 'Detallar aquí',
                                                          },
                                                          className: 'col',
                                                        }, 
]
}


