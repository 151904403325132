import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Inicio',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },
  {
    label: 'Sistemas',
    isTitle: true
  },
  {
    label: 'Mis Sistemas',
    icon: 'tool',
    subItems: [
      {
        label: 'Ver Sistemas',
        link: 'view-device',
      },
      {
        label: 'Registrar uno nuevo',
        link: 'add-device'
      },
    ]
  },
  {
    label: 'Pruebas',
    icon: 'file-text',
    link: 'testing',
  },
  
 
  {
    label: 'Cuenta',
    isTitle: true
  },
  {
    label: 'Mi Cuenta',
    icon: 'user',
  },
  {
    label: 'Soporte',
    icon: 'anchor',
  },
 
];
